.services-container {
	.react-slideshow-container .nav {
		top: 100%;
	}

	.react-slideshow-container .right-arrow-icon {
		left: -50px;

		@media (max-width: 500px) {
			left: 50px;
		}
	}

	.react-slideshow-container .left-arrow-icon {
		left: -100px !important;

		@media (max-width: 500px) {
			left: 0px!important;
		}
	}
}