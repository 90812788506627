.search-page{
	padding-top: 5px;
	@media (max-width: $mobile)
	{
		padding-top: 25px;
	}
}

.submit-search-bt{
    cursor: pointer;
}
.search-menu{
	border-bottom: 2px solid $border-color;
    background: $light;
	padding-top:15px;
	border-radius:5px;
	box-shadow:0 3px 18px #0000000d;
}
.search-menu ul {
	display: inline-flex;
	justify-content: start;
	li{
		list-style-type: none;
		a{
			display: block;
			padding-left: 10px;
			padding-right: 10px;
			min-height: 35px;
			color: $light;
		}
		a:hover{
			border-bottom: 2px solid $background3;
			color: $background3;
			font-weight: bold;
		}
	}
}
.search-result-wrap{
	article{
		h3 a{
			display: flex;
			align-items: center;
			justify-content: flex-start;
			gap: 10px;
			
		}
		h3 svg{
			background: #eee;
			border-radius: 100%;
		}
	}
}
