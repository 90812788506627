.enable-widget-button{ 
	 cursor: pointer;
	 background: #fff;
	 border-radius: 100%;
	 width: 25px;
	 height: 25px;
	 box-shadow: #0000000d; 
	 position: absolute;
	 left: 0;
	 top: -1px;
}
.enable-widget-button-right{
	animation: 2s enabled;
	cursor: pointer;
	 background: #fff;
	 border-radius: 100%;
	 width: 25px;
	 height: 25px;
	 box-shadow: #0000000d; 
	 position: absolute;
	 left: 62%;
	top: -1px;
}
.enable-widget-container{
	padding: 2px;
	width: 70px;
	position: relative;
	height: 25px;
	border-radius: 30px;
}

@keyframes enabled{
	0%{
		left: 0%;
	}
	100%{
		left: 62%;
	}
}