a {
	//color: $light;
	text-decoration: none;
}

%light-article {
	box-shadow: 0 3px 18px #0000000d;
	border: 2px solid #f8f8f8;
}

.home-simple-banner {
	min-height: 450px;
	color: $light;
	position: relative;
	background-position: center;
	margin-top: -50px;

	.banner-overlay {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		background: rgba(0, 0, 0, 0.28);
		//background: rgba(250,0,200,0.6);
		height: 100%;
		z-index: 1;
	}

	.banner-main-content {
		z-index: 2;
		position: relative;
		text-align: center;
		padding-top: 150px;



	}

	.app-bt {
		border-color: $light;
		color: $light;
		display: inline-flex;
		margin-right: 10px;
		border-radius: 8px;
		transition: all 0.3s;
		margin-top: 25px;

		&:hover {

			background: rgba(255, 255, 255, 0.4);
			font-weight: bold;
		}
	}
}

.home-app-core {

	@media (max-width: $large) {
		padding-left: 0 !important;
	}
}

.who-am-i-section {
	.about-me-image-wrap {
		width: 150px;
		height: 150px;
		margin: 0 auto;
		margin-bottom: 25px;
		border-radius: 100%;
	}
}

.why-choose-me-section {
	.important-icon {
		width: 32px;
		height: 32px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		border-radius: 100%;
		background: $background3;
		color: $light;
		font-weight: bold;
		margin: 0 auto;
		margin-bottom: 25px;
	}

	h3 {
		color: $background3;
		text-align: center;
	}
}

.last-books-section {
	min-height: 450px;
	//background: $light;
	padding-bottom: 50px;

	.last-books-section-container {
		width: 80%;
		margin: auto;

		@media (max-width: $tablet) {
			width: 100%;
		}
	}

	h2 {
		text-align: center;
		text-transform: uppercase;
	}

	.last-books-wrap {
		//display: flex;
		//flex-direction: column;
		// background: #f7f7f7; 
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));

		@media (max-width: 900px) {
			display: flex;
			flex-direction: column;
		}


	}

	.last-books-cover {
		background: $violet;
		width: 200px;
		min-height: 100px;
	}

	.see-books {
		text-align: center;
	}
}

.about-me {
	/*font-size: 40px;
	font-weight: bold;*/
}

.shop-wrap {
	background: $blue-banner;
}

.shop-article-wrap {
	display: grid;

}

.techstack-section {
	.elem-logo-icon {
		max-height: 80px !important;
		margin: 15px;
		vertical-align: middle;
	}
}

.contact-section {
	min-height: 600px;
	//background: #f5f5f2;
	// background: $light;
	// background: $app-logo-color;
	background: $light-bg-contact;
	color: $light;

	h2 {
		color: $dark;
	}

	.contact-container {
		text-align: center;
		padding: 25px;
		// background: $light;

		@media (max-width: $tablet) {
			padding: 5px;
		}
	}

	.contact-form-wrap {
		// background: $light;
		min-height: 400px;
		margin: 0 auto;
		padding: 25px;
		color: $dark;

		@media (max-width: $tablet) {
			padding: 10px;
		}

		.show-contact-input {


			display: flex;
			align-items: center;
			gap: 10px;

			.add-bt {
				width: 32px;
				height: 32px;
				display: flex;
				justify-content: center;
				align-items: center;
				flex-direction: column;
				color: $light;
				background: $golden-color;
				transition: all 0.3s;
				cursor: pointer;
				border-radius: 100%;

				&:hover {
					transform: scale(1.01) translateY(-5px);
				}
			}
		}

		.app-bt {
			margin: auto;
			color: $light !important;
			border-color: $light;
			background: $dark !important;
			margin-top: 25px;

			svg {
				fill: $light;
			}

			&:hover {
				background: $golden-color;
				color: $dark;
			}
		}
	}

	.checkbox-label {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		gap: 5p;
	}

	.contact-wrap {
		width: 80%;
		margin: 0 auto;
		max-width: 1400px;

		@media (max-width: $tablet) {
			width: 95%;
		}

		label {
			//	display: none;
		}

		input[type=submit] {
			background: $golden-color;
			color: $dark;
		}

		.form-title {
			display: block;
			text-align: left;
			margin-bottom: 10px;
		}
	 
		textarea{
			width: 100%; 
			min-width: 100%; 
			min-height: 50px;
		}
		input[type=text],
		input[type=email],
		select {
			// margin-bottom: 25px;
			// min-height: 35px;

			// min-width: 100%;
			// padding: 10px;
			// //border-radius: 5px;
			// border: 1px solid #ddd;
			// box-shadow: 0 3px 18px #dddddd;
			// border-bottom: 2px solid $light;
			// color: $dark;
			// display: block;
			// border-radius: 5px;
			// margin: 0 0 25px 0;
			@media (max-width: $tablet) {
				width: 100%;
			}
		}

		input::placeholder {
			color: $light;
		}

		textarea {

			min-height: 200px;
		}
	}
}

.portfolio-section {
	min-height: 450px;
	// background: $dark;
	color: $light;

	.portfolio-wrap {
		display: flex;
		justify-content: space-between;
	}


}

.sample-project-section {
	.elem-col {
		min-width: 250px;
		width: 30%;

		@media(max-width: $large) {
			min-width: 100%;

		}
	}

	.elem-row {
		@media (max-width: $large) {
			flex-direction: column;


		}
	}

	article {
		padding-bottom: 10px !important;
		min-height: 200px !important;

		@media (max-width: $large) {

			padding: 0;
		}

		.article-core {
			background-color: $light !important;
			color: $dark !important;
			padding-top: 15px;

			@media (max-width: $large) {

				// padding: 0!important;
			}

			h3 {
				color: $background3 !important;
				border-bottom: 2px solid $background3;
				margin-bottom: 15px;
			}
		}

		.article-cover {
			@media (max-width: $large) {
				min-height: 150px;

			}
		}
	}
}

.services-studio {

	// background: linear-gradient(#49d, $background3); 
	// color: $light;
	padding-bottom: 80px;

	.elem-row {
		@media (max-width: $large) {
			flex-direction: column;
		}

	}

	.elem-col {
		@media (max-width: $large) {
			// flex-direction: column;
			min-width: 100% !important;
		}
	}

	article {
		@media (max-width: $large) {
			padding: 0px !important;
			min-width: 100%;
		}

		.article-cover {
			@media(max-width: $large) {
				min-height: 150px;
			}

		}
	}

	.article-core {
		background: $light !important;
		color: $dark !important;
		padding-top: 15px;

		h3 {
			color: $background3 !important;
			border-bottom: 2px solid transparent !important;
			margin-bottom: 15px;
		}
	}

	.elem-col-animate {
		color: $dark;

		h3 {
			color: $background3;
		}
	}

	.elem-col {
		min-width: 200px;
		width: 30%;
	}

}

.services-section {
	min-height: 600px;
	width: 50%;
	margin: 0 auto;
	min-width: 250px;

	@media (max-width: 1000px) {
		width: 70%;
	}

	@media (max-width: 500px) {
		width: 100%;
	}

	.services-wrap {
		display: grid;

		grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
		//grid-template-columns: 50% 50%;
		justify-content: center;
		width: 80%;
		margin: 0 auto;

		@media (max-width: $tablet) {
			display: grid;
			flex-direction: column;
			width: 100%;
		}
	}

	h2 {
		text-align: center;
		color: $light;
	}


}

.shop-section {
	min-height: 600px;
	// background: $light;


	// .shop-article-wrap {
	// 	display: grid;
	// 	justify-content: space-between;
	// 	grid-template-columns: 50% 50%;

	// 	@media (max-width:$tablet) {
	// 		flex-direction: column;
	// 		width: 100%;

	// 	}


	// }
}

.blog-section {
	min-height: 450px;
	// padding: 0 0 20% 20%;
}