.sticky-nav-wrap{

	position: fixed;
	top: 65px;
	right: 0;
	z-index: 14;
	background: $background3;
	width: 50px;
	min-height: 50px;
	padding: 2px;
	width: 32px;
	border-radius: 8px;
	svg{

		fill:  $light;
		width: 25px;
		height: 25px;
	}
	li{
		list-style-type: none;
	}
	ul{
		padding: 0;
	}
}