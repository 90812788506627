.bread-crumb {
	li {
		list-style-type: none;
		display: inline-flex;
		align-items: center;

		a {
			padding: 5px;
			color: $light;
			&:hover {
				text-decoration: underline;
			}
		}

		&::after {
			content: '>';
			display: inline-block;

		}
	}

	li.main-article-list {
		&::after {
			content: '';
		}

		a {
			// color: #4ad;
			font-weight: bold; 
			text-transform: uppercase; 
			// &:hover{
			// 	color: $background3;
			// }
		}
	}
}

.blog-core {

	// display: flex;

	justify-content: center;

	.blog-core-main {
		max-width: 800px;
		width: 100%;
		margin: auto;
		article{
			margin-bottom: 10px;
			border-radius: 20px;
		}
	}

	img {
		max-width: 100%;
	}

}

.blog-page {

	.article-cover {
		max-width: 250px;
		width: 250px;
		min-width: 200px;
		background: transparent;
		padding: 20px;
		@extend %light-article;

		@media(max-width: $tablet) {
			width: 100%;
			max-width: 100%;
			min-width: 100%;
		}

		img {
			//max-width: 250px;
			//width: 250px;
		}
	}

	.article-core {

		padding-top: 25px;
	}

	.blog-title {
		text-align: center;
		display: flex;
		justify-content: space-between;
	}

	.blog-article {

		border: 1px solid #2b2b2c;
		padding: 50px;
		border-radius: 5px;
		text-align: left;

	}

	.blog-article {
		border-color: $light-bg;
		box-shadow: 0 3px 18px #0000000d;
	}
}

.blog-page-core {
	display: flex;
	justify-content: center;

	@media (max-width: $large) {
		flex-direction: column;

	}

	.blog-main-core {
		width: 80%;
		max-width: 2000px;
		min-width: 250px;
		@media (max-width: $large2) {
			width: 100%;
		}

		.blog-top-title {
			display: flex;
			flex-direction: column;
			padding-top: 30px;

			h1 {
				text-transform: capitalize;
				font-size: 50px;
			}
		}

		.blog-date-published {
			color: $grayMenu;
			padding-left: 10px;

			&:hover {
				color: $light;
			}
		}
	}

	.message-sent {
		width: 100%;
		margin-bottom: 50px;
		box-shadow: 0 3px 18px #0000000d;
	}
}

.comment-list {
	//text-align: center; 
	padding-bottom: 25px;

	h2 {
		text-transform: capitalize;
		color: $app-logo-color;
	}

	article {
		padding: 20px;
		padding-top: 10px;
		border-radius: 5px;
	}

	.comment-title-wrap {

		display: flex;
		justify-content: space-between;
		align-items: center;
	}
}

.comment-wrap {

	text-align: center;
	background: $light;
	padding: 20px;
	border-radius: 20px;
	// width: 80%;
	// margin: 0 auto;

	// padding: 25px;
	padding-bottom: 100px;
	padding-top: 25px;
	color: $background3;
	label {

		display: block;
		text-align: left;
	}

	input,
	textarea {
		display: block;
		width: 100%;
		border: none;
		border-bottom: 2px solid $background3;
	}

	.app-bt,
	button {
		@extend .app-bt;
		border-color: $background3;
		color: $background3;
		background: transparent;
		margin: 0;
	}

	h2 {
		margin-bottom: 15px;
	}
}

.blog-main-content {
	min-height: 250px;
	// background: $light;
	// padding: 20px;
	// min-height: 400px;
	margin-top: 10px;
	margin-bottom: 25px;
	border-radius: 5px;  
	font-size: 125%;
}

.comment-button-wrap {
	display: flex;
	justify-content: space-between;

	h2 {
		text-align: left;
	}

	button {
		@media (max-width: $large) {
			max-height: 35px;
			justify-content: center;
		}
	}

	// button {
	// 	background: #000;
	// 	color: #fff;
	// 	padding-left: 25px;
	// 	padding-right: 25px;
	// 	border-radius: 20px;
	// }

}