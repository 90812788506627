.enhenced-blog-wrap{
    padding: 10px;
    h2{
        text-align: center;
    }
    .homepage-bt{
        margin: 0 auto;
    }
    .gasycms-pagination-articles-wrap{

        display: grid;   
		grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
        gap: 10px;
        padding: 25px;
        @media (max-width: 900px)
        { 
            display: flex; 
            flex-direction: column;
        }
    }
}