body {
	// background: fixed no-repeat, $body-main-background; 
	// background: $body-color;
	// color: $light;
	background: $light;
	color: $dark;
}

html {
	scroll-behavior: smooth !important; //enable smooth scrolling for all pages
}

* {
	box-sizing: border-box;
	font-family: PoppinsRegular;
}

.email-saved,
.simple-notice {
	border-left: 2px solid #4ad;
	padding: 10px;
	margin-top: 25px;
}

.app-flex {
	display: flex;
}

.flex-centered {
	display: flex;
	//flex-direction: column;
	align-items: center;
	//justify-content: center;
}

.section-default-wrap {
	max-width: 2000px;
	margin: 0 auto;
	padding: 0 25px;
}

.g5 {
	gap: 5px;
}

.default-container {
	width: 100%;
	margin: auto;
	padding-left: 10%;
	padding-right: 10%;

	@media (max-width: $large) {
		padding-left: 25px;
		padding-right: 25px;
	}
}

.addIcon {
	display: inline-flex;
	border-radius: 100%;
	background: linear-gradient($red-color, $grayMenu);
	padding: 5px;
	margin-left: 10px;
	color: $light;
}

.app-error {
	background: $red-color;
	color: $light;
	padding: 20px;
	border-radius: 5px;
}

section {
	h2 {
		text-transform: capitalize;
		min-height: 100px;
		padding-top: 50px;
		//padding-bottom: 50px;
	}
}

.loading-simple {
	display: flex;
	font-weight: bold;
	align-items: center;
	justify-content: center;
	color: #4ad;
}

p {
	margin-top: 15px;
	margin-bottom: 10px;
	line-height: 25px;
}

* {
	//font-family: "Poppins Regular";
}

.message-sent {
	background: $light;
	color: $background3;
	// text-transform: capitalize;
	padding: 25px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	font-weight: bold;
	bottom: 0;
	width: 40%;
	min-width: 200px;
	max-width: 800px;
	margin: auto;
	margin-top: 25px;
	margin-bottom: 25px;
	border-radius: 20px;
	box-shadow: 0 3px 18px #0000000D;

	svg {
		fill: $background3;
	}

	a {
		color: $background3;
	}

}

.send-message-bt {
	background: #2b2b2c;
	color: $light;
	min-width: 100px;
	height: 50px;
	text-align: center;
	cursor: pointer;

}

.message-sent-check {
	fill: $light;
	width: 50px;
	height: 32px;
	// width: 40px;
	// height: 32px;
	// border-radius: 100%;
	// border: 2px solid $light;
}

.about-section {
	.about-section-wrap {
		// width: 80%;
		margin: auto;
		padding-top: 50px;
		min-height: 600px;
	}
}


.app-core {

	//padding-top:50px;
	//margin-top: 100px;
	@media (max-width: $large) {
		//margin-top: 50px;
	}
}