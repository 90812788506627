.new-classroom-form{
	color: $grayMenu; 
	background: $light; 
	padding: 25px;
	margin-top: 25px; 
	border-radius: 8px;
	input{
		border: 1px solid #ddd;
		display: block;
		min-width: 100%;
		min-height: 35px;
	}
	select{
		border: 1px solid #ddd;
		display: block;
		min-width: 100%;
		min-height: 35px;
	}
	button{

	}
}
.profile-home-dashboard{
	max-width: 250px;
	.dashboard-item{
		background: $light; 
		margin-top: 25px; 
		margin-bottom: 25px; 
		padding: 25px; 
		color: $grayMenu; 
		border-radius: 5px; 

	}
	h2{
		color: $background3;
		font-size: 16px;
	}
}
.question-icon{
	border-radius: 100%; 
	background: $background3; 
	color: $light; 
	display: flex; 
	flex-direction: column; 
	justify-content: center; 
	align-items: center;
	font-size: 14px;
	padding: 5px; 
	cursor: pointer;
	max-width: 32px;
	min-height: 32px;
}