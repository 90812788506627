.search-input-header {
	z-index: 14;
	background: $light;
	position: fixed;
	top: 0;
	left: 0;
	height: 50px;
	padding: 5px;
	color: $grayMenu;
	min-width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;

	.search-input-category {
		position: absolute;
		top: 8px;
		right: 80px;
		border: 1px solid transparent !important;

	}

	.search-header-result {
		position: absolute;
		top: 55px;
		background: $light;
		width: 100%;
		min-width: 100%;
		left: 0;
		min-height: 200px;
		transition: all 0.3s;
		padding: 5px;

		&:hover {
			overflow-y: scroll;
		}
	}

	form {
		width: 98%;
		background: #eee;

		input {
			display: block;
			width: 100%;
			padding-left: 35px;
			border: 1px solid transparent;
			border-bottom: 2px solid $background3;
		}

		.search-input-icon {
			position: absolute;
			top: 15px;
			left: 15px;
			color: $background3;
		}
	}

	.close-search-input {
		cursor: pointer;
		position: absolute;
		top: 7px;
		right: 32px;
		border: 1px solid transparent;
		box-shadow: 0 3px 18px #0000000d;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 32px;
		height: 32px;
		padding: 5px;
		border-radius: 100%;

	}

}

@keyframes showFixedHeader {
	0% {

		top: -15px;
	}

	100% {

		top: 0;
	}
}
.app-header-fixed {
	background: rgba(255, 255, 255, 0.9)!important;
	border-bottom: 1px solid transparent !important;
	color: $dark;
	transition: all 0.3s;
	animation: showFixedHeader 1s !important;
	min-height: 50px;
	top: 0;
	padding: 0 !important;

	.app-logo-wrap {
		a.appName .author-name {
			color: $dark!important;
		}
	}

	.app-bt {

		color: $dark!important;
		border-color: $light!important;
	}

	.search-icon {
		fill: $dark;
	}

	.default-menu {
		li a {
			color: $dark!important;
		}

		a.active-page {
			// background:$light;
			border-color: $background3!important;
			color: $background3!important;
		}
	}
}


.app-header {
	display: flex;
	min-height: 50px;
	height: 50px;
	align-items: center;
	margin: auto;
	top: 0;
	left: 0;
	width: 100%;
	background: transparent ;
	z-index: 13;
	border-bottom: 1px solid transparent !important;

	.section-default-wrap {
		display: flex;
		justify-content: space-between;
		padding-left: 25px;
		padding-right: 25px;
		width: 100%;
		max-width: 1400px;
	}


	.search-input {
		//border: 1px solid $app-logo-color;
		min-height: 35px;
		padding: 5px;
		width: 40%;
		min-width: 200px;
		padding-left: 20px;
		color: $dark;
		border-radius: 20px;
		//box-shadow: 0 2px 4px $app-logo-color;
		background: $light-form-bg;

		&:hover {
			//box-shadow: 0 2px 8px $app-logo-color;
		}

		@media (max-width: $tablet) {
			min-width: 180px;
		}

		@media (max-width: $mobile) {
			margin-left: 4px;
		}
	}

	.submit-search-bt {
		border: none;
		background: none;
		margin-left: -35px;

		svg {
			fill: $background3;
			width: 25px;
			height: 25px;
		}
	}

	@media (max-width: $large) {
		padding-left: 5px;
		padding-right: 5px;
	}

	.top-right-menu {
		display: flex;
		justify-content: center;
		align-items: center;

		@media (max-width: $mobile) {
			padding-left: 12px;
		}

		.default-menu {
			ul {
				display: flex;
			}

			li {
				list-style-type: none;
			}

			a {
				display: flex;
				flex-direction: column;
				align-items: center;
				padding: 5px;
				//border-top: 4px solid transparent;
				min-height: 50px;
				justify-content: center;
				text-transform: capitalize;
				font-size: 14px;
				color: $light;
			}

			a:hover {
				// border-top: 4px solid $light;
				//border-bottom-color: transparent;
			}

			a.active-page {
				//  border-top: 4px solid $light;
				border-bottom: 2px solid $light;
				//background-color: $background3;

				color: $light;
			}

			span.hands {
				display: none;
			}

			@media (max-width: $large) {
				display: none;
			}
		}

		.mobile-sticky-menu-scroll {
			position: fixed;
			top: 55px;
			left: -15px;
			display: none;
			z-index: 2;

			@media (max-width: $large) {
				display: block;

			}

			li {
				list-style-type: none;

			}

			a {
				display: block;
				background: $light;
				color: $background3;
				padding: 5px;
				padding-left: 25px;
				//transform: skewX(-15deg);
				margin-left: -50px;
			}

			.active-page {
				border-bottom: 2px solid $light;
				background: linear-gradient($background2, $background3);
				color: $light;
				transition: all 0.3s;
				animation: showActivePage 1s;
				//min-width: 150px;
				margin-left: 0;

			}

			@keyframes showActivePage {
				0% {
					margin-left: -50px;
				}

				100% {
					margin-left: 0;
				}

			}

		}

		.mobile-default-menu-wrap {
			display: none;
			position: relative;

			@media(max-width: $large) {
				display: block;
			}

			.mobile-default-menu {
				position: absolute;
				top: 35px;
				right: 0;
				min-width: 200px;
				min-height: 150px;
				padding: 10px;
				background: $light;

				li {
					list-style-type: none;
					border-bottom: 2px solid $border-color;
				}

				a {
					display: block;
					min-height: 35px;
				}
			}

		}

		a {
			color: #444;
		}

		.mobile-top-right-desktop-menu {
			display: flex;
			justify-content: center;
			align-items: center;
			padding-left: 10px;

			a {
				padding: 10px 5px;
				min-width: 100px;
				border-radius: 8px;

				&:hover {
					background: $background3;
					color: $light;
					text-decoration: none;
				}
			}

			@media(max-width: $tablet) {
				display: none;
			}
		}

		.mobile-top-right-menu {
			display: none;

			@media (max-width: $tablet) {
				display: block;
			}

			.show-more-bt {
				// margin-top: 35px;
				cursor: pointer;
				margin-right: 10px;
			}

			.mobile-top-right-submenu {
				position: absolute;
				top: 35px;
				right: 0;
				background: $dark;
				box-shadow: 0 3px 16px #0000000d;
				padding: 5px;
				display: flex;
				flex-direction: column;
				min-width: 250px;
				min-height: 150px;
				justify-content: center;
				border-top: 2px solid $border-color2;
			}
		}

		.client-connect-bt {
			margin-right: 10px;
		}

		svg {
			fill: $app-logo-color;
			// margin-right: 10px;
		}

		.app-bt {
			//@extend .got-a-project-bt-top;
			display: flex;
			justify-content: center;
			//margin-left: 2px;
			min-width: 50px;
			color: $background3;
			padding-top: 15px;
			padding-bottom: 15px;
			margin-left: 5px;
			border: 1px solid $background3;
			border-radius: 8px;
			padding-left: 10px;
			padding-right: 10px;
			padding-top: 5px;
			padding-bottom: 5px;

			@media (max-width: $mobile) {
				padding: 2px;
				margin-left: 2px;
				border: none;

			}

			&:hover {
				//text-decoration: underline;
			}
		}
	}

	.app-logo-wrap {
		display: flex;
		// background: #eee;
		width: 60%;
		align-items: center;
		gap: 15px;

		@media(max-width:$tablet) {
			width: 70%;
		}

		.search-icon {
			color: $grayMenu;
			margin-top: 3px;
			cursor: pointer;
			transition: all 0.3s;

			&:hover {
				transform: scale(1.02) translateY(-5px);
			}
		}
	}

	.app-logo {
		font-weight: bold;
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-direction: column;

		// width: 90%; 
		// background: #eee;
		.appName {
			display: flex;
			align-items: center;
			gap: 5px;
			color: $light !important;
			// margin-top: 15px;
			text-transform: uppercase;
			font-size: 16px;
			box-sizing: border-box;
			height: 30px;
			//margin-left: -32px;


			@media (max-width: $large) {

				margin-top: 0;
				padding: 0;
				margin-left: 0;
			}

			img {
				width: 48px;
				height: 48px;

				// margin-top: 15px;
				@media (max-width: $tablet) {
					// width: 45px;
					// height: 45px;
					margin-top: 0;
				}

				@media (max-width: $mobile) {
					margin-left: -15px;
				}
			}

			.author-name {
				color: $light !important;
				text-transform: uppercase;
				font-weight: bold;
				font-size: 19px;

				@media (max-width: $tablet) {
					//	display: none;
				}
			}

		}

		.main-menu-bt {
			// background: $dark;
			fill: $light;
			border-radius: 100%;
			width: 32px;
			height: 32px;
			padding: 5px;
			margin-right: 10px;
			cursor: pointer;
			//display: none;
			fill: $app-logo-color;

			@media (max-width: $large) {
				display: block;
			}
		}

		.skills {
			font-weight: normal;
		}

		a {
			text-transform: uppercase;
			//border: 1px solid $dark;
			padding: 5px;
			padding-left: 25px;
			padding-right: 25px;
			color: $dark;
			//font-style: italic;
			font-weight: 600;
			font-size: 25px;
			// border-radius: 5px;
			text-transform: capitalize;
			//opacity: 0.7;
		}
	}

}

.app-main-menu {
	position: relative;
	//top: 50px;
	left: 0;
	width: 100%;
	min-height: 50px;
	display: flex;
	align-items: center;
	padding-bottom: 0;
	padding-top: 0px;
	z-index: 7;
	background: $light-bg;

	.section-default-wrap {
		width: 100%;
		display: flex;
		align-items: flex-start;
		margin: 0 auto;
		padding-left: 25px;
		padding-right: 25px;
		justify-content: flex-start;
	}

	@media (max-width: $large) {
		display: none;
	}

	ul {
		display: flex;
		align-items: center;
		justify-content: start;
		align-items: center;
		min-height: 50px;
		padding-bottom: 0;

		@media (max-width: $large) {
			padding-left: 0;
			padding-right: 0;
		}
	}

	li {
		list-style-type: none;
		//transition: transform 0.5s;
		// position: relative;

		@media (max-width: $large) {
			//display: none;
		}

		&:hover>.app-sub-menu {
			//display: inline-flex; 
			width: 50%;
			left: 30%;
			display: grid;
			grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
			background: linear-gradient(45deg, $dark, transparent);
			animation: menuTransform 1s;
		}
	}

	.services-desktop {
		.app-sub-menu {
			padding-left: 10%;
			padding-right: 10%;
			min-height: 250px;
			gap: 5px;
			position: absolute;
			z-index: 2;

			a {
				// background-color: $body-color;
				// margin: 20px;
				border-radius: 5px;
				display: flex;
				align-items: center;
				justify-content: center;
				text-align: center;
				padding: 10px;
				min-height: 80px;
				font-weight: bold;
				color: $dark;

				&:hover {
					border-color: transparent;
				}
			}
		}
	}

	.app-sub-menu {
		//position: absolute;
		top: 50px;
		background: $light;
		width: 100%;
		max-width: 100%;
		min-width: 200px;
		padding: 20px;
		min-width: 250px;
		min-height: 150px;
		display: none;
		//border-top: 2px solid $light;
		justify-content: flex-start;

		li {
			// width: 100%;
			// min-width: 100%;
			//min-height: 150px;
		}

		a {
			color: $dark;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;
			padding: 10px;
			min-height: 100%;
			height: 100%;
			border-radius: 8px;

			&:hover {
				color: $app-logo-color;
				font-weight: bold;
			}
		}

	}

	li.mobile-bt {
		// @media (max-width: $large)
		// {
		// 	display: block;
		// }
	}

	li a {
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 10px;
		padding-top: 5px;
		color: $background3;
		font-size: 16px;
		text-decoration: none;
		border-top: 2px solid transparent;
		height: 50px;
		text-transform: uppercase;

		&:hover {
			border-bottom: 3px solid $background3;

			font-weight: bold;

			// background: $red-color;

		}
	}

	//li a:hover{
	// text-decoration: underline;
	//}
}

.app-banner {
	min-height: 250px;
	display: flex;
	justify-content: center;
	background-size: cover;
	background-attachment: fixed;
	//background: #2b2b2c;
	//background: #f8f8f8;
	background: $app-logo-color;
	animation: 1s bannerAnim;
	border-bottom: 2px solid #f8f8f8;
	box-shadow: 0 3px 18px #0000000d;
	padding-left: 10%;
	padding-right: 10%;
	justify-content: flex-start;
	padding-bottom: 50px;

	@media (max-width: $mobile) {
		padding-left: 25px;
		padding-right: 25px;
	}

	h2 {
		font-size: 50px;
	}

	.banner-bg {

		color: $light;
		min-height: 400px;
		width: 30%;
		max-width: 30%;
		min-width: 250px;
		padding-top: 0;
		text-align: left;

		@media (max-width: $large) {
			text-align: center;

		}

		@media (max-width: $large2) {
			width: 80%;
			max-width: 80%;
			margin: auto;
		}
	}

	.banner-hero {

		border-radius: 25px 25px 0 0;
		min-height: 400px;
		display: flex;
		flex-direction: column;
		align-items: start;
		justify-content: center;

		@media (max-width: $large) {
			align-items: center;
			width: 100%;
		}
	}
}

@keyframes bannerAnim {
	0% {
		padding-top: 50px;
	}

	100% {
		padding-top: 25px;
	}
}


.contact-bar {
	//background: linear-gradient(45deg,$green, $fav-blue);
	background: $contact-bar-color;
	color: $background3;

	a {
		color: $background3;
		padding: 5px;

		&:hover {
			color: $background3;
		}
	}

	.contact-bar-wrap {
		display: flex;
		justify-content: center;
		width: 80%;
		margin: auto;
		gap: 10px;

		@media (max-width: $tablet) {
			width: 100%;
			font-size: 95%;
		}
	}
}

.show-left-bar {


	// animation: 1s showLeftBarAnim;
}

.hide-left-bar {


	// animation: 2s hideLeftBarAnim;
}

.services-mobile {
	display: none;
}

.app-left-menu {
	position: fixed;
	top: 0;
	left: 0;
	width: 75%;
	min-width: 200px;
	height: 100%;
	min-height: 100%;
	z-index: 12;
	border-right: none;

	li a {
		padding-top: 10px;
		padding-bottom: 10px;
	}

	.modal-box,
	#leftMenu {
		margin-top: 0;
		position: absolute;
		top: 0;
		left: 0;
		border-radius: 0;
		height: 100%;
		min-height: 1000px;
		padding: 0;
		max-width: 70%;
		border: none;
	}

	.close-bt {
		position: absolute;
		top: 25px;
		right: 25px;
		color: $app-logo-color;
		padding: 5px;
		display: flex;
		justify-content: center;
		align-items: center;
		font-weight: bold;
		width: 25px;
		height: 25px;
		background: $light;
		border-radius: 100%;
	}

	.close-icon-bt {
		fill: $light;
		width: 32px;
		height: auto;
		//position: absolute;
		//top: 15px;
		//right: 10px;
		cursor: pointer;
	}

	.app-main-left-menu {
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding: 10px;
		//padding-left: 50px;
		//padding-right: 50px;
		justify-content: center;

		li {
			list-style-type: none;
			min-height: 30px;
			margin: 0;
			padding: 0;
			//display: flex;
			//align-items: center;

			@media(max-width: $large) {
				//margin-top: 10px;
			}
		}

		a {
			height: 100%;
			display: flex;
			align-items: center;

			@media(max-width: $large) {
				//min-height: 50px;
				color: $grayMenu;
			}
		}
	}

	.app-main-left-menu a {
		color: $dark;
	}

	.app-main-left-menu svg {
		width: 26px;
		height: 26px;
	}

	.menu-cover {
		background: $dark ;
		width: 100%;
		height: 55px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 5px;
		padding-bottom: 10px;
		color: $light;

		h2 {
			color: $light;
			margin: 0;
		}

		img {
			display: block;
			width: 40px;
			height: auto;
			//transform: rotateX(180deg);
			border: 2px solid $light;
			margin-left: 5px;
			border-radius: 100%;
		}

	}

	.about-author {
		text-align: center;
		color: $grayMenu;
	}

	.close-bt-2 .close-bar-top {
		background: $app-logo-color;
	}

	h2 {
		color: $dark;
		// border: 1px solid $light;
		text-align: center;
		text-transform: capitalize;
		margin-bottom: 10px;
		margin-top: 25px;
		padding: 10px;
		display: none;

		@media (max-width: $tablet) {
			display: block;
		}
	}

	.services-desktop {
		display: none;
	}

	.services-mobile {
		display: block;

		a {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding-top: 0;
			padding-bottom: 0;
		}

		.app-sub-menu {
			padding-left: 50px;

			a {
				color: $grayMenu;
				border: 1px solid $grayMenu;
				padding: 10px;
				border-radius: 5px;
				margin-top: 5px;
			}
		}
	}
}

@keyframes showLeftBarAnim {
	0% {
		width: 40%;
	}

	100% {
		width: 75%;
	}
}

@keyframes hideLeftBarAnim {
	0% {
		width: 40%;
	}

	100% {
		width: 0%;
	}
}

.close-bt-2 {
	width: 32px;
	height: 32px;
	margin-top: 32px;
	position: absolute;
	top: 5px;
	right: 25px;
	cursor: pointer;

	.close-bar-top {
		min-width: 32px;
		min-height: 5px;
		background: $light;
		transform: rotate(45deg);

	}

	.close-bar-bottom {
		min-width: 32px;
		min-height: 5px;
		background: $light;
		transform: rotate(-45deg);
		margin-top: -5px;
	}
}

form {

	input {
		padding: 5px;
	}
}

@keyframes menuTransform {
	0% {
		transform: scale(0.5);
	}

	100% {
		transform: scale(1.0);
	}
}