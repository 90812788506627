.app-footer {
	background: $light !important;
	color: #333 !important;
	min-height: 200px;
	padding: 25px;
	padding-top: 80px;

	// border-top-left-radius: 100%;
	// border-top-right-radius: 100%;
	h2 {
		min-height: 50px;
	}

	.newsletter-form {
		.app-bt {
			border-color: $background3;
			margin-top: 25px;
			color: $background3;
			transition: all 0.3s;
			min-width: 100%;
			justify-content: center;
			text-align: center;

			&:hover {
				background: $background3;
				color: $light;
			}
		}

		input {
			border-radius: 8px;
			min-width: 100%;
		}
	}

	a.go-top {
		background: $golden-color;
		color: $dark;
		border-radius: 100%;
		border: 2px solid $golden-color;
		width: 44px;
		height: 44px;
		position: fixed;
		bottom: 10px;
		right: 10px;
		z-index: 5;
		display: flex;
		box-shadow: 0 3px 18px #ffd700dd;
		align-items: center;
		justify-content: center;

		svg {
			fill: $background3;
		}
	}

	a,
	p {
		color: #333 !important;

		&:hover {
			//color: $light;
		}
	}

	a.homepage-bt {
		color: $light;
		margin-top: 15px;

		@media(max-width: $large) {
			margin: 0 auto;
			margin-top: 15px;
		}
	}

	li {
		list-style-type: none;
	}

	a.contact-us-bt {
		// background: $app-logo-color;
		color: $light;
		padding-left: 10px;
		padding-right: 10px;
		padding-top: 5px;
		padding-bottom: 5px;
		min-height: 35px;
		display: flex;
		align-items: center;
		max-width: 200px;
		min-width: 100px;
		border-radius: 5px;
		margin: auto;
		margin-top: 25px;
		justify-content: space-between;
		border: 1px solid $light;

		&:hover {
			padding-top: 6px;
			// background-color: $green-terminal;
		}

	}

	.app-footer-container {
		justify-content: space-between;
		width: 80%;
		padding: 25px 25px;
		margin: 0 auto;
		max-width: 1400px;

		.about-wrap {
			@media (max-width: $large) {
				display: flex;
				flex-direction: column;
				align-items: center;
			}
		}

		@media (max-width: $large) {
			flex-direction: column;
			justify-content: center;
			row-gap: 50px;
			text-align: center;
		}
	}

	.copyright-wrap {
		text-align: center;
		// border-top: 2px solid $light;
		margin-top: 50px;
		min-height: 50px;
		display: flex;
		justify-content: space-between;
		padding-left: 10%;
		padding-right: 10%;
		//	border-top: 2px solid #e5e5e5;
		padding-top: 10px;

		@media (max-width: $large) {
			flex-direction: column;
			justify-content: center;
			align-items: center;
			gap: 15px;
		}

		@media (max-width: $mobile) {
			padding-left: 25px;
			padding-right: 25px;
		}

		.footer-logo-wrap {
			align-items: center;
			gap: 10px;
			font-weight: bold;
		}
	}

	.social-wrap {
		// min-width: 150px;
		padding-top: 25px;
		@media (max-width: $large) {
			max-width: 250px;
			margin: auto;
		}

		.custom-social-icon {


			margin-right: 15px;
		}

		.app-flex {
			justify-content: space-between;
		}

		svg {
			width: 24px;
			height: 24px;
			fill: #333 !important;
		}
	}

	.elem-row {
		justify-content: space-between;
		padding: 0;
	}

	.elem-col {
		width: 30%;
		min-width: 200px;

		@media (max-width: $large) {
			width: 100%;
			text-align: center;

		}
	}
}

.app-footer-service {
	display: flex;
	// background: $app-logo-color;
	color: $light;
	min-height: 100px;
	justify-content: space-between;
	padding: 50px;


	@media (max-width: $tablet) {
		padding-left: 25px;
		padding-right: 25px;
	}

	.section-default-wrap {
		display: flex;
		justify-content: space-between;
		width: 100%;
		padding-left: 25px;
		padding-right: 25px;
	}
}

.address-wrap {
	.animate-icon {
		font-size: 32px;
	}
}