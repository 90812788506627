.about-section{
	//padding: 50px;
.about-section-wrap{
	padding-left: 10%;
	padding-right: 10%;
	@media (max-width: $large)
	{
		padding-left: 25px;
		padding-right: 25px;
	}
}
}
.about-me{

justify-content: space-between;
}
