.projects-container{
    padding: 25px;
    .pagination-articles-wrap{
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
        gap: 25px; 
        @media (max-width: $tablet)
        {
            display: flex; 
            flex-direction: column;
            gap: 25px;
            justify-content: center;
        }
    }
	.homepage-bt{
		margin: 0 auto;
		margin-top: 15px;
	}
}