.app-login {
	width: 80%;
	margin: 0 auto;

	@media (max-width: $mobile) {
		width: 95%;
	}

	.login-title {
		display: flex;
		// justify-content: center;
		align-items: center;
		// border-bottom: 2px solid #f8f8f8;
		//padding-bottom: 10px;
		margin-bottom: 10px;
		color: $dark;
		padding-bottom: 50px;

		.app-bt {
			margin: 0;
		}
	}

	.error-login-wrap {
		background: #eee;
		margin-top: 20px 25px;
		border-radius: 20px;
		display: flex;
		justify-content: center;
		align-items: center;
		min-height: 55px;
		color: $grayMenu; 
		margin-bottom: 15px;
	}

	.login-logo-wrap {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		min-width: 100%;
		gap: 25px;
	}

	.app-login-wrap {

		padding: 25px;
		// border-radius: 10px;
		display: flex;
		align-items: center;
		justify-content: center;
		background: $light;
		flex-direction: column;
		margin: 0 auto;
		//border: 2px solid #000;
		max-width: 400px;
		//margin-top: 150px;
		margin-bottom: 50px;
		box-shadow: 0 3px 18px $shadow-color;
		transition: all 0.3s;
		border-radius: 10px;

		@media (max-width: $tablet) {
			margin-top: 10px;
		}

		&:hover {
			padding-top: 28px;
		}

		h2 {
			color: $background3;
		}
	}

	p.error {
		background: #eee;
		color: $grayMenu;
		text-align: center;
		padding: 5px;
		border-radius: 5px;
	}

	.login-form {
		width: 100%;
	}

	.login-footer {
		display: flex;
		gap: 25px;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		color: $grayMenu;

		a {
			color: $grayMenu;
		}
	}

	button {

		background: transparent;
		border: 1px solid $background3;
		color: $background3;
		border-radius: 20px;

		&:hover {
			font-weight: bold;
			background: transparent;
			// color: $dark;
		}
	}

	h2 {
		//margin-bottom: 10px;
	}

	select {
		display: block;
		height: 35px;
		width: 100%;
		border: none;
		border-bottom: 1px solid $contact-bar-color;
		margin-top: 10px;
	}

	input {
		margin-bottom: 10px;
		display: block;
		width: 100%;
		height: 35px;
		border: none;
		border-bottom: 1px solid $contact-bar-color;
	}

	.loginMessage a {
		color: $grayMenu;

		&:hover {
			color: $dark;
		}
	}

	label {
		margin-top: 10px;
		color: $dark;
	}
}