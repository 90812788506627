.modal-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 17;
    display: flex;
    justify-content: center;
    overflow-y: scroll;
    .modal-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.4);
        z-index: 10;
    }
    .modal-box {
        position: relative;
        background: $light;
        width: 50%; // left: 30%;
        min-width: 250px;
        max-width: 800px;
        margin: auto;
        margin-top: 50px;
        padding: 25px;
        border: 1px solid $app-logo-color;
        z-index: 11;
        border-radius: 5px;
        color: $grayMenu;
        border-radius: 8px; 

        a {
            color: $background3;
        }
        @media (max-width: $tablet) {
            width: 90%;
        }
    }
    .modal-footer{
    	display: flex; 
    	align-items: flex-end; 
    	padding: 10px 25px; 
    	border-top: 1px solid $contact-bar-color;
    	margin-top: 25px;
    }
}