.who-am-i{
    background: $light;
	width: 70%;
	padding: 50px;
	min-height: 400px;
	color: $dark;
	margin-left: 25px;
	z-index: 10;
	min-width: 200px;
	max-width: 2000px;
	margin-top: 25px;
	@media (max-width: $mobile)
	{
		margin: 0 auto;
		width: 100%;
		padding: 25px;
	}
	p{
		line-height: 25px;
	}
	h2{
		min-height: 50px;
		padding-bottom: 5px;
		color: $background3;
	}
	.app-bt{
	    margin: 0;
		background: $background3;
		border-color: $background3; 
		color: $light;
		border-radius: 8px;
		text-transform: uppercase;
	}
	.homepage-bt{
		max-width: 150px;
		text-align: center;
		justify-content: center;
		&:hover{
			background: $dark;
		}
	}
}