@font-face{
	font-family: "PoppinsBold";
	src: local('Poppins-Bold'), 
	     url("../../../../fonts/Poppins/Poppins-Bold.ttf") format("truetype");
}

@font-face{
	font-family: "PoppinsRegular";
	src: local("Poppins-Regular"),
	     url("../../../../fonts/Poppins/Poppins-Regular.ttf") format("truetype");
}