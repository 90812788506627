a {
  text-decoration: none;
}

.blog-page .article-cover {
  box-shadow: 0 3px 18px rgba(0, 0, 0, 0.0509803922);
  border: 2px solid #f8f8f8;
}

.home-simple-banner {
  min-height: 450px;
  color: #ffffff;
  position: relative;
  background-position: center;
  margin-top: -50px;
}
.home-simple-banner .banner-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.28);
  height: 100%;
  z-index: 1;
}
.home-simple-banner .banner-main-content {
  z-index: 2;
  position: relative;
  text-align: center;
  padding-top: 150px;
}
.home-simple-banner .app-bt, .home-simple-banner .app-profile-page-container .modal-box button, .app-profile-page-container .modal-box .home-simple-banner button,
.home-simple-banner .comment-wrap button,
.comment-wrap .home-simple-banner button, .home-simple-banner .homepage-bt, .home-simple-banner .load-more-blog-bt, .home-simple-banner .transparent-bt {
  border-color: #ffffff;
  color: #ffffff;
  display: inline-flex;
  margin-right: 10px;
  border-radius: 8px;
  transition: all 0.3s;
  margin-top: 25px;
}
.home-simple-banner .app-bt:hover, .home-simple-banner .app-profile-page-container .modal-box button:hover, .app-profile-page-container .modal-box .home-simple-banner button:hover,
.home-simple-banner .comment-wrap button:hover,
.comment-wrap .home-simple-banner button:hover, .home-simple-banner .homepage-bt:hover, .home-simple-banner .load-more-blog-bt:hover, .home-simple-banner .transparent-bt:hover {
  background: rgba(255, 255, 255, 0.4);
  font-weight: bold;
}

@media (max-width: 980px) {
  .home-app-core {
    padding-left: 0 !important;
  }
}

.who-am-i-section .about-me-image-wrap {
  width: 150px;
  height: 150px;
  margin: 0 auto;
  margin-bottom: 25px;
  border-radius: 100%;
}

.why-choose-me-section .important-icon {
  width: 32px;
  height: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  background: #7a01fa;
  color: #ffffff;
  font-weight: bold;
  margin: 0 auto;
  margin-bottom: 25px;
}
.why-choose-me-section h3 {
  color: #7a01fa;
  text-align: center;
}

.last-books-section {
  min-height: 450px;
  padding-bottom: 50px;
}
.last-books-section .last-books-section-container {
  width: 80%;
  margin: auto;
}
@media (max-width: 768px) {
  .last-books-section .last-books-section-container {
    width: 100%;
  }
}
.last-books-section h2 {
  text-align: center;
  text-transform: uppercase;
}
.last-books-section .last-books-wrap {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
}
@media (max-width: 900px) {
  .last-books-section .last-books-wrap {
    display: flex;
    flex-direction: column;
  }
}
.last-books-section .last-books-cover {
  background: #443344;
  width: 200px;
  min-height: 100px;
}
.last-books-section .see-books {
  text-align: center;
}

.about-me {
  /*font-size: 40px;
  font-weight: bold;*/
}

.shop-wrap {
  background: #3737ac;
}

.shop-article-wrap {
  display: grid;
}

.techstack-section .elem-logo-icon {
  max-height: 80px !important;
  margin: 15px;
  vertical-align: middle;
}

.contact-section {
  min-height: 600px;
  background: #f4f4f4;
  color: #ffffff;
}
.contact-section h2 {
  color: #000;
}
.contact-section .contact-container {
  text-align: center;
  padding: 25px;
}
@media (max-width: 768px) {
  .contact-section .contact-container {
    padding: 5px;
  }
}
.contact-section .contact-form-wrap {
  min-height: 400px;
  margin: 0 auto;
  padding: 25px;
  color: #000;
}
@media (max-width: 768px) {
  .contact-section .contact-form-wrap {
    padding: 10px;
  }
}
.contact-section .contact-form-wrap .show-contact-input {
  display: flex;
  align-items: center;
  gap: 10px;
}
.contact-section .contact-form-wrap .show-contact-input .add-bt {
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #ffffff;
  background: #ffd700;
  transition: all 0.3s;
  cursor: pointer;
  border-radius: 100%;
}
.contact-section .contact-form-wrap .show-contact-input .add-bt:hover {
  transform: scale(1.01) translateY(-5px);
}
.contact-section .contact-form-wrap .app-bt, .contact-section .contact-form-wrap .app-profile-page-container .modal-box button, .app-profile-page-container .modal-box .contact-section .contact-form-wrap button,
.contact-section .contact-form-wrap .comment-wrap button,
.comment-wrap .contact-section .contact-form-wrap button, .contact-section .contact-form-wrap .homepage-bt, .contact-section .contact-form-wrap .load-more-blog-bt, .contact-section .contact-form-wrap .transparent-bt {
  margin: auto;
  color: #ffffff !important;
  border-color: #ffffff;
  background: #000 !important;
  margin-top: 25px;
}
.contact-section .contact-form-wrap .app-bt svg, .contact-section .contact-form-wrap .app-profile-page-container .modal-box button svg, .app-profile-page-container .modal-box .contact-section .contact-form-wrap button svg,
.contact-section .contact-form-wrap .comment-wrap button svg,
.comment-wrap .contact-section .contact-form-wrap button svg, .contact-section .contact-form-wrap .homepage-bt svg, .contact-section .contact-form-wrap .load-more-blog-bt svg, .contact-section .contact-form-wrap .transparent-bt svg {
  fill: #ffffff;
}
.contact-section .contact-form-wrap .app-bt:hover, .contact-section .contact-form-wrap .app-profile-page-container .modal-box button:hover, .app-profile-page-container .modal-box .contact-section .contact-form-wrap button:hover,
.contact-section .contact-form-wrap .comment-wrap button:hover,
.comment-wrap .contact-section .contact-form-wrap button:hover, .contact-section .contact-form-wrap .homepage-bt:hover, .contact-section .contact-form-wrap .load-more-blog-bt:hover, .contact-section .contact-form-wrap .transparent-bt:hover {
  background: #ffd700;
  color: #000;
}
.contact-section .checkbox-label {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5p;
}
.contact-section .contact-wrap {
  width: 80%;
  margin: 0 auto;
  max-width: 1400px;
}
@media (max-width: 768px) {
  .contact-section .contact-wrap {
    width: 95%;
  }
}
.contact-section .contact-wrap input[type=submit] {
  background: #ffd700;
  color: #000;
}
.contact-section .contact-wrap .form-title {
  display: block;
  text-align: left;
  margin-bottom: 10px;
}
.contact-section .contact-wrap textarea {
  width: 100%;
  min-width: 100%;
  min-height: 50px;
}
@media (max-width: 768px) {
  .contact-section .contact-wrap input[type=text],
  .contact-section .contact-wrap input[type=email],
  .contact-section .contact-wrap select {
    width: 100%;
  }
}
.contact-section .contact-wrap input::placeholder {
  color: #ffffff;
}
.contact-section .contact-wrap textarea {
  min-height: 200px;
}

.portfolio-section {
  min-height: 450px;
  color: #ffffff;
}
.portfolio-section .portfolio-wrap {
  display: flex;
  justify-content: space-between;
}

.sample-project-section .elem-col {
  min-width: 250px;
  width: 30%;
}
@media (max-width: 980px) {
  .sample-project-section .elem-col {
    min-width: 100%;
  }
}
@media (max-width: 980px) {
  .sample-project-section .elem-row {
    flex-direction: column;
  }
}
.sample-project-section article {
  padding-bottom: 10px !important;
  min-height: 200px !important;
}
@media (max-width: 980px) {
  .sample-project-section article {
    padding: 0;
  }
}
.sample-project-section article .article-core {
  background-color: #ffffff !important;
  color: #000 !important;
  padding-top: 15px;
}
.sample-project-section article .article-core h3 {
  color: #7a01fa !important;
  border-bottom: 2px solid #7a01fa;
  margin-bottom: 15px;
}
@media (max-width: 980px) {
  .sample-project-section article .article-cover {
    min-height: 150px;
  }
}

.services-studio {
  padding-bottom: 80px;
}
@media (max-width: 980px) {
  .services-studio .elem-row {
    flex-direction: column;
  }
}
@media (max-width: 980px) {
  .services-studio .elem-col {
    min-width: 100% !important;
  }
}
@media (max-width: 980px) {
  .services-studio article {
    padding: 0px !important;
    min-width: 100%;
  }
}
@media (max-width: 980px) {
  .services-studio article .article-cover {
    min-height: 150px;
  }
}
.services-studio .article-core {
  background: #ffffff !important;
  color: #000 !important;
  padding-top: 15px;
}
.services-studio .article-core h3 {
  color: #7a01fa !important;
  border-bottom: 2px solid transparent !important;
  margin-bottom: 15px;
}
.services-studio .elem-col-animate {
  color: #000;
}
.services-studio .elem-col-animate h3 {
  color: #7a01fa;
}
.services-studio .elem-col {
  min-width: 200px;
  width: 30%;
}

.services-section {
  min-height: 600px;
  width: 50%;
  margin: 0 auto;
  min-width: 250px;
}
@media (max-width: 1000px) {
  .services-section {
    width: 70%;
  }
}
@media (max-width: 500px) {
  .services-section {
    width: 100%;
  }
}
.services-section .services-wrap {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  justify-content: center;
  width: 80%;
  margin: 0 auto;
}
@media (max-width: 768px) {
  .services-section .services-wrap {
    display: grid;
    flex-direction: column;
    width: 100%;
  }
}
.services-section h2 {
  text-align: center;
  color: #ffffff;
}

.shop-section {
  min-height: 600px;
}

.blog-section {
  min-height: 450px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.search-input-header {
  z-index: 14;
  background: #ffffff;
  position: fixed;
  top: 0;
  left: 0;
  height: 50px;
  padding: 5px;
  color: rgba(0, 0, 0, 0.4);
  min-width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.search-input-header .search-input-category {
  position: absolute;
  top: 8px;
  right: 80px;
  border: 1px solid transparent !important;
}
.search-input-header .search-header-result {
  position: absolute;
  top: 55px;
  background: #ffffff;
  width: 100%;
  min-width: 100%;
  left: 0;
  min-height: 200px;
  transition: all 0.3s;
  padding: 5px;
}
.search-input-header .search-header-result:hover {
  overflow-y: scroll;
}
.search-input-header form {
  width: 98%;
  background: #eee;
}
.search-input-header form input {
  display: block;
  width: 100%;
  padding-left: 35px;
  border: 1px solid transparent;
  border-bottom: 2px solid #7a01fa;
}
.search-input-header form .search-input-icon {
  position: absolute;
  top: 15px;
  left: 15px;
  color: #7a01fa;
}
.search-input-header .close-search-input {
  cursor: pointer;
  position: absolute;
  top: 7px;
  right: 32px;
  border: 1px solid transparent;
  box-shadow: 0 3px 18px rgba(0, 0, 0, 0.0509803922);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  padding: 5px;
  border-radius: 100%;
}

@keyframes showFixedHeader {
  0% {
    top: -15px;
  }
  100% {
    top: 0;
  }
}
.app-header-fixed {
  background: rgba(255, 255, 255, 0.9) !important;
  border-bottom: 1px solid transparent !important;
  color: #000;
  transition: all 0.3s;
  animation: showFixedHeader 1s !important;
  min-height: 50px;
  top: 0;
  padding: 0 !important;
}
.app-header-fixed .app-logo-wrap a.appName .author-name {
  color: #000 !important;
}
.app-header-fixed .app-bt, .app-header-fixed .app-profile-page-container .modal-box button, .app-profile-page-container .modal-box .app-header-fixed button,
.app-header-fixed .comment-wrap button,
.comment-wrap .app-header-fixed button, .app-header-fixed .homepage-bt, .app-header-fixed .load-more-blog-bt, .app-header-fixed .transparent-bt {
  color: #000 !important;
  border-color: #ffffff !important;
}
.app-header-fixed .search-icon {
  fill: #000;
}
.app-header-fixed .default-menu li a {
  color: #000 !important;
}
.app-header-fixed .default-menu a.active-page {
  border-color: #7a01fa !important;
  color: #7a01fa !important;
}

.app-header {
  display: flex;
  min-height: 50px;
  height: 50px;
  align-items: center;
  margin: auto;
  top: 0;
  left: 0;
  width: 100%;
  background: transparent;
  z-index: 13;
  border-bottom: 1px solid transparent !important;
}
.app-header .section-default-wrap {
  display: flex;
  justify-content: space-between;
  padding-left: 25px;
  padding-right: 25px;
  width: 100%;
  max-width: 1400px;
}
.app-header .search-input {
  min-height: 35px;
  padding: 5px;
  width: 40%;
  min-width: 200px;
  padding-left: 20px;
  color: #000;
  border-radius: 20px;
  background: #f4f7f8;
}
@media (max-width: 768px) {
  .app-header .search-input {
    min-width: 180px;
  }
}
@media (max-width: 450px) {
  .app-header .search-input {
    margin-left: 4px;
  }
}
.app-header .submit-search-bt {
  border: none;
  background: none;
  margin-left: -35px;
}
.app-header .submit-search-bt svg {
  fill: #7a01fa;
  width: 25px;
  height: 25px;
}
@media (max-width: 980px) {
  .app-header {
    padding-left: 5px;
    padding-right: 5px;
  }
}
.app-header .top-right-menu {
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 450px) {
  .app-header .top-right-menu {
    padding-left: 12px;
  }
}
.app-header .top-right-menu .default-menu ul {
  display: flex;
}
.app-header .top-right-menu .default-menu li {
  list-style-type: none;
}
.app-header .top-right-menu .default-menu a {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px;
  min-height: 50px;
  justify-content: center;
  text-transform: capitalize;
  font-size: 14px;
  color: #ffffff;
}
.app-header .top-right-menu .default-menu a.active-page {
  border-bottom: 2px solid #ffffff;
  color: #ffffff;
}
.app-header .top-right-menu .default-menu span.hands {
  display: none;
}
@media (max-width: 980px) {
  .app-header .top-right-menu .default-menu {
    display: none;
  }
}
.app-header .top-right-menu .mobile-sticky-menu-scroll {
  position: fixed;
  top: 55px;
  left: -15px;
  display: none;
  z-index: 2;
}
@media (max-width: 980px) {
  .app-header .top-right-menu .mobile-sticky-menu-scroll {
    display: block;
  }
}
.app-header .top-right-menu .mobile-sticky-menu-scroll li {
  list-style-type: none;
}
.app-header .top-right-menu .mobile-sticky-menu-scroll a {
  display: block;
  background: #ffffff;
  color: #7a01fa;
  padding: 5px;
  padding-left: 25px;
  margin-left: -50px;
}
.app-header .top-right-menu .mobile-sticky-menu-scroll .active-page {
  border-bottom: 2px solid #ffffff;
  background: linear-gradient(#f521b6, #7a01fa);
  color: #ffffff;
  transition: all 0.3s;
  animation: showActivePage 1s;
  margin-left: 0;
}
@keyframes showActivePage {
  0% {
    margin-left: -50px;
  }
  100% {
    margin-left: 0;
  }
}
.app-header .top-right-menu .mobile-default-menu-wrap {
  display: none;
  position: relative;
}
@media (max-width: 980px) {
  .app-header .top-right-menu .mobile-default-menu-wrap {
    display: block;
  }
}
.app-header .top-right-menu .mobile-default-menu-wrap .mobile-default-menu {
  position: absolute;
  top: 35px;
  right: 0;
  min-width: 200px;
  min-height: 150px;
  padding: 10px;
  background: #ffffff;
}
.app-header .top-right-menu .mobile-default-menu-wrap .mobile-default-menu li {
  list-style-type: none;
  border-bottom: 2px solid #ddd;
}
.app-header .top-right-menu .mobile-default-menu-wrap .mobile-default-menu a {
  display: block;
  min-height: 35px;
}
.app-header .top-right-menu a {
  color: #444;
}
.app-header .top-right-menu .mobile-top-right-desktop-menu {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 10px;
}
.app-header .top-right-menu .mobile-top-right-desktop-menu a {
  padding: 10px 5px;
  min-width: 100px;
  border-radius: 8px;
}
.app-header .top-right-menu .mobile-top-right-desktop-menu a:hover {
  background: #7a01fa;
  color: #ffffff;
  text-decoration: none;
}
@media (max-width: 768px) {
  .app-header .top-right-menu .mobile-top-right-desktop-menu {
    display: none;
  }
}
.app-header .top-right-menu .mobile-top-right-menu {
  display: none;
}
@media (max-width: 768px) {
  .app-header .top-right-menu .mobile-top-right-menu {
    display: block;
  }
}
.app-header .top-right-menu .mobile-top-right-menu .show-more-bt {
  cursor: pointer;
  margin-right: 10px;
}
.app-header .top-right-menu .mobile-top-right-menu .mobile-top-right-submenu {
  position: absolute;
  top: 35px;
  right: 0;
  background: #000;
  box-shadow: 0 3px 16px rgba(0, 0, 0, 0.0509803922);
  padding: 5px;
  display: flex;
  flex-direction: column;
  min-width: 250px;
  min-height: 150px;
  justify-content: center;
  border-top: 2px solid rgb(60, 66, 87);
}
.app-header .top-right-menu .client-connect-bt {
  margin-right: 10px;
}
.app-header .top-right-menu svg {
  fill: #00d6ff;
}
.app-header .top-right-menu .app-bt, .app-header .top-right-menu .app-profile-page-container .modal-box button, .app-profile-page-container .modal-box .app-header .top-right-menu button,
.app-header .top-right-menu .comment-wrap button,
.comment-wrap .app-header .top-right-menu button, .app-header .top-right-menu .homepage-bt, .app-header .top-right-menu .load-more-blog-bt, .app-header .top-right-menu .transparent-bt {
  display: flex;
  justify-content: center;
  min-width: 50px;
  color: #7a01fa;
  padding-top: 15px;
  padding-bottom: 15px;
  margin-left: 5px;
  border: 1px solid #7a01fa;
  border-radius: 8px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}
@media (max-width: 450px) {
  .app-header .top-right-menu .app-bt, .app-header .top-right-menu .app-profile-page-container .modal-box button, .app-profile-page-container .modal-box .app-header .top-right-menu button,
  .app-header .top-right-menu .comment-wrap button,
  .comment-wrap .app-header .top-right-menu button, .app-header .top-right-menu .homepage-bt, .app-header .top-right-menu .load-more-blog-bt, .app-header .top-right-menu .transparent-bt {
    padding: 2px;
    margin-left: 2px;
    border: none;
  }
}
.app-header .app-logo-wrap {
  display: flex;
  width: 60%;
  align-items: center;
  gap: 15px;
}
@media (max-width: 768px) {
  .app-header .app-logo-wrap {
    width: 70%;
  }
}
.app-header .app-logo-wrap .search-icon {
  color: rgba(0, 0, 0, 0.4);
  margin-top: 3px;
  cursor: pointer;
  transition: all 0.3s;
}
.app-header .app-logo-wrap .search-icon:hover {
  transform: scale(1.02) translateY(-5px);
}
.app-header .app-logo {
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}
.app-header .app-logo .appName {
  display: flex;
  align-items: center;
  gap: 5px;
  color: #ffffff !important;
  text-transform: uppercase;
  font-size: 16px;
  box-sizing: border-box;
  height: 30px;
}
@media (max-width: 980px) {
  .app-header .app-logo .appName {
    margin-top: 0;
    padding: 0;
    margin-left: 0;
  }
}
.app-header .app-logo .appName img {
  width: 48px;
  height: 48px;
}
@media (max-width: 768px) {
  .app-header .app-logo .appName img {
    margin-top: 0;
  }
}
@media (max-width: 450px) {
  .app-header .app-logo .appName img {
    margin-left: -15px;
  }
}
.app-header .app-logo .appName .author-name {
  color: #ffffff !important;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 19px;
}
.app-header .app-logo .main-menu-bt {
  fill: #ffffff;
  border-radius: 100%;
  width: 32px;
  height: 32px;
  padding: 5px;
  margin-right: 10px;
  cursor: pointer;
  fill: #00d6ff;
}
@media (max-width: 980px) {
  .app-header .app-logo .main-menu-bt {
    display: block;
  }
}
.app-header .app-logo .skills {
  font-weight: normal;
}
.app-header .app-logo a {
  text-transform: uppercase;
  padding: 5px;
  padding-left: 25px;
  padding-right: 25px;
  color: #000;
  font-weight: 600;
  font-size: 25px;
  text-transform: capitalize;
}

.app-main-menu {
  position: relative;
  left: 0;
  width: 100%;
  min-height: 50px;
  display: flex;
  align-items: center;
  padding-bottom: 0;
  padding-top: 0px;
  z-index: 7;
  background: #f8f8f8;
}
.app-main-menu .section-default-wrap {
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin: 0 auto;
  padding-left: 25px;
  padding-right: 25px;
  justify-content: flex-start;
}
@media (max-width: 980px) {
  .app-main-menu {
    display: none;
  }
}
.app-main-menu ul {
  display: flex;
  align-items: center;
  justify-content: start;
  align-items: center;
  min-height: 50px;
  padding-bottom: 0;
}
@media (max-width: 980px) {
  .app-main-menu ul {
    padding-left: 0;
    padding-right: 0;
  }
}
.app-main-menu li {
  list-style-type: none;
}
.app-main-menu li:hover > .app-sub-menu {
  width: 50%;
  left: 30%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  background: linear-gradient(45deg, #000, transparent);
  animation: menuTransform 1s;
}
.app-main-menu .services-desktop .app-sub-menu {
  padding-left: 10%;
  padding-right: 10%;
  min-height: 250px;
  gap: 5px;
  position: absolute;
  z-index: 2;
}
.app-main-menu .services-desktop .app-sub-menu a {
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 10px;
  min-height: 80px;
  font-weight: bold;
  color: #000;
}
.app-main-menu .services-desktop .app-sub-menu a:hover {
  border-color: transparent;
}
.app-main-menu .app-sub-menu {
  top: 50px;
  background: #ffffff;
  width: 100%;
  max-width: 100%;
  min-width: 200px;
  padding: 20px;
  min-width: 250px;
  min-height: 150px;
  display: none;
  justify-content: flex-start;
}
.app-main-menu .app-sub-menu a {
  color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 10px;
  min-height: 100%;
  height: 100%;
  border-radius: 8px;
}
.app-main-menu .app-sub-menu a:hover {
  color: #00d6ff;
  font-weight: bold;
}
.app-main-menu li a {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  padding-top: 5px;
  color: #7a01fa;
  font-size: 16px;
  text-decoration: none;
  border-top: 2px solid transparent;
  height: 50px;
  text-transform: uppercase;
}
.app-main-menu li a:hover {
  border-bottom: 3px solid #7a01fa;
  font-weight: bold;
}

.app-banner {
  min-height: 250px;
  display: flex;
  justify-content: center;
  background-size: cover;
  background-attachment: fixed;
  background: #00d6ff;
  animation: 1s bannerAnim;
  border-bottom: 2px solid #f8f8f8;
  box-shadow: 0 3px 18px rgba(0, 0, 0, 0.0509803922);
  padding-left: 10%;
  padding-right: 10%;
  justify-content: flex-start;
  padding-bottom: 50px;
}
@media (max-width: 450px) {
  .app-banner {
    padding-left: 25px;
    padding-right: 25px;
  }
}
.app-banner h2 {
  font-size: 50px;
}
.app-banner .banner-bg {
  color: #ffffff;
  min-height: 400px;
  width: 30%;
  max-width: 30%;
  min-width: 250px;
  padding-top: 0;
  text-align: left;
}
@media (max-width: 980px) {
  .app-banner .banner-bg {
    text-align: center;
  }
}
@media (max-width: 1022px) {
  .app-banner .banner-bg {
    width: 80%;
    max-width: 80%;
    margin: auto;
  }
}
.app-banner .banner-hero {
  border-radius: 25px 25px 0 0;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
}
@media (max-width: 980px) {
  .app-banner .banner-hero {
    align-items: center;
    width: 100%;
  }
}

@keyframes bannerAnim {
  0% {
    padding-top: 50px;
  }
  100% {
    padding-top: 25px;
  }
}
.contact-bar {
  background: #e5e5e5;
  color: #7a01fa;
}
.contact-bar a {
  color: #7a01fa;
  padding: 5px;
}
.contact-bar a:hover {
  color: #7a01fa;
}
.contact-bar .contact-bar-wrap {
  display: flex;
  justify-content: center;
  width: 80%;
  margin: auto;
  gap: 10px;
}
@media (max-width: 768px) {
  .contact-bar .contact-bar-wrap {
    width: 100%;
    font-size: 95%;
  }
}

.services-mobile {
  display: none;
}

.app-left-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 75%;
  min-width: 200px;
  height: 100%;
  min-height: 100%;
  z-index: 12;
  border-right: none;
}
.app-left-menu li a {
  padding-top: 10px;
  padding-bottom: 10px;
}
.app-left-menu .modal-box,
.app-left-menu #leftMenu {
  margin-top: 0;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 0;
  height: 100%;
  min-height: 1000px;
  padding: 0;
  max-width: 70%;
  border: none;
}
.app-left-menu .close-bt {
  position: absolute;
  top: 25px;
  right: 25px;
  color: #00d6ff;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  width: 25px;
  height: 25px;
  background: #ffffff;
  border-radius: 100%;
}
.app-left-menu .close-icon-bt {
  fill: #ffffff;
  width: 32px;
  height: auto;
  cursor: pointer;
}
.app-left-menu .app-main-left-menu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
  justify-content: center;
}
.app-left-menu .app-main-left-menu li {
  list-style-type: none;
  min-height: 30px;
  margin: 0;
  padding: 0;
}
.app-left-menu .app-main-left-menu a {
  height: 100%;
  display: flex;
  align-items: center;
}
@media (max-width: 980px) {
  .app-left-menu .app-main-left-menu a {
    color: rgba(0, 0, 0, 0.4);
  }
}
.app-left-menu .app-main-left-menu a {
  color: #000;
}
.app-left-menu .app-main-left-menu svg {
  width: 26px;
  height: 26px;
}
.app-left-menu .menu-cover {
  background: #000;
  width: 100%;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  padding-bottom: 10px;
  color: #ffffff;
}
.app-left-menu .menu-cover h2 {
  color: #ffffff;
  margin: 0;
}
.app-left-menu .menu-cover img {
  display: block;
  width: 40px;
  height: auto;
  border: 2px solid #ffffff;
  margin-left: 5px;
  border-radius: 100%;
}
.app-left-menu .about-author {
  text-align: center;
  color: rgba(0, 0, 0, 0.4);
}
.app-left-menu .close-bt-2 .close-bar-top {
  background: #00d6ff;
}
.app-left-menu h2 {
  color: #000;
  text-align: center;
  text-transform: capitalize;
  margin-bottom: 10px;
  margin-top: 25px;
  padding: 10px;
  display: none;
}
@media (max-width: 768px) {
  .app-left-menu h2 {
    display: block;
  }
}
.app-left-menu .services-desktop {
  display: none;
}
.app-left-menu .services-mobile {
  display: block;
}
.app-left-menu .services-mobile a {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 0;
  padding-bottom: 0;
}
.app-left-menu .services-mobile .app-sub-menu {
  padding-left: 50px;
}
.app-left-menu .services-mobile .app-sub-menu a {
  color: rgba(0, 0, 0, 0.4);
  border: 1px solid rgba(0, 0, 0, 0.4);
  padding: 10px;
  border-radius: 5px;
  margin-top: 5px;
}

@keyframes showLeftBarAnim {
  0% {
    width: 40%;
  }
  100% {
    width: 75%;
  }
}
@keyframes hideLeftBarAnim {
  0% {
    width: 40%;
  }
  100% {
    width: 0%;
  }
}
.close-bt-2 {
  width: 32px;
  height: 32px;
  margin-top: 32px;
  position: absolute;
  top: 5px;
  right: 25px;
  cursor: pointer;
}
.close-bt-2 .close-bar-top {
  min-width: 32px;
  min-height: 5px;
  background: #ffffff;
  transform: rotate(45deg);
}
.close-bt-2 .close-bar-bottom {
  min-width: 32px;
  min-height: 5px;
  background: #ffffff;
  transform: rotate(-45deg);
  margin-top: -5px;
}

form input {
  padding: 5px;
}

@keyframes menuTransform {
  0% {
    transform: scale(0.5);
  }
  100% {
    transform: scale(1);
  }
}
.app-footer {
  background: #ffffff !important;
  color: #333 !important;
  min-height: 200px;
  padding: 25px;
  padding-top: 80px;
}
.app-footer h2 {
  min-height: 50px;
}
.app-footer .newsletter-form .app-bt, .app-footer .newsletter-form .app-profile-page-container .modal-box button, .app-profile-page-container .modal-box .app-footer .newsletter-form button,
.app-footer .newsletter-form .comment-wrap button,
.comment-wrap .app-footer .newsletter-form button, .app-footer .newsletter-form .homepage-bt, .app-footer .newsletter-form .load-more-blog-bt, .app-footer .newsletter-form .transparent-bt {
  border-color: #7a01fa;
  margin-top: 25px;
  color: #7a01fa;
  transition: all 0.3s;
  min-width: 100%;
  justify-content: center;
  text-align: center;
}
.app-footer .newsletter-form .app-bt:hover, .app-footer .newsletter-form .app-profile-page-container .modal-box button:hover, .app-profile-page-container .modal-box .app-footer .newsletter-form button:hover,
.app-footer .newsletter-form .comment-wrap button:hover,
.comment-wrap .app-footer .newsletter-form button:hover, .app-footer .newsletter-form .homepage-bt:hover, .app-footer .newsletter-form .load-more-blog-bt:hover, .app-footer .newsletter-form .transparent-bt:hover {
  background: #7a01fa;
  color: #ffffff;
}
.app-footer .newsletter-form input {
  border-radius: 8px;
  min-width: 100%;
}
.app-footer a.go-top {
  background: #ffd700;
  color: #000;
  border-radius: 100%;
  border: 2px solid #ffd700;
  width: 44px;
  height: 44px;
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 5;
  display: flex;
  box-shadow: 0 3px 18px rgba(255, 215, 0, 0.8666666667);
  align-items: center;
  justify-content: center;
}
.app-footer a.go-top svg {
  fill: #7a01fa;
}
.app-footer a,
.app-footer p {
  color: #333 !important;
}
.app-footer a.homepage-bt {
  color: #ffffff;
  margin-top: 15px;
}
@media (max-width: 980px) {
  .app-footer a.homepage-bt {
    margin: 0 auto;
    margin-top: 15px;
  }
}
.app-footer li {
  list-style-type: none;
}
.app-footer a.contact-us-bt {
  color: #ffffff;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  min-height: 35px;
  display: flex;
  align-items: center;
  max-width: 200px;
  min-width: 100px;
  border-radius: 5px;
  margin: auto;
  margin-top: 25px;
  justify-content: space-between;
  border: 1px solid #ffffff;
}
.app-footer a.contact-us-bt:hover {
  padding-top: 6px;
}
.app-footer .app-footer-container {
  justify-content: space-between;
  width: 80%;
  padding: 25px 25px;
  margin: 0 auto;
  max-width: 1400px;
}
@media (max-width: 980px) {
  .app-footer .app-footer-container .about-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
@media (max-width: 980px) {
  .app-footer .app-footer-container {
    flex-direction: column;
    justify-content: center;
    row-gap: 50px;
    text-align: center;
  }
}
.app-footer .copyright-wrap {
  text-align: center;
  margin-top: 50px;
  min-height: 50px;
  display: flex;
  justify-content: space-between;
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 10px;
}
@media (max-width: 980px) {
  .app-footer .copyright-wrap {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
  }
}
@media (max-width: 450px) {
  .app-footer .copyright-wrap {
    padding-left: 25px;
    padding-right: 25px;
  }
}
.app-footer .copyright-wrap .footer-logo-wrap {
  align-items: center;
  gap: 10px;
  font-weight: bold;
}
.app-footer .social-wrap {
  padding-top: 25px;
}
@media (max-width: 980px) {
  .app-footer .social-wrap {
    max-width: 250px;
    margin: auto;
  }
}
.app-footer .social-wrap .custom-social-icon {
  margin-right: 15px;
}
.app-footer .social-wrap .app-flex {
  justify-content: space-between;
}
.app-footer .social-wrap svg {
  width: 24px;
  height: 24px;
  fill: #333 !important;
}
.app-footer .elem-row {
  justify-content: space-between;
  padding: 0;
}
.app-footer .elem-col {
  width: 30%;
  min-width: 200px;
}
@media (max-width: 980px) {
  .app-footer .elem-col {
    width: 100%;
    text-align: center;
  }
}

.app-footer-service {
  display: flex;
  color: #ffffff;
  min-height: 100px;
  justify-content: space-between;
  padding: 50px;
}
@media (max-width: 768px) {
  .app-footer-service {
    padding-left: 25px;
    padding-right: 25px;
  }
}
.app-footer-service .section-default-wrap {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-left: 25px;
  padding-right: 25px;
}

.address-wrap .animate-icon {
  font-size: 32px;
}

body {
  background: #ffffff;
  color: #000;
}

html {
  scroll-behavior: smooth !important;
}

* {
  box-sizing: border-box;
  font-family: PoppinsRegular;
}

.email-saved,
.simple-notice {
  border-left: 2px solid #4ad;
  padding: 10px;
  margin-top: 25px;
}

.app-flex {
  display: flex;
}

.flex-centered {
  display: flex;
  align-items: center;
}

.section-default-wrap {
  max-width: 2000px;
  margin: 0 auto;
  padding: 0 25px;
}

.g5 {
  gap: 5px;
}

.default-container {
  width: 100%;
  margin: auto;
  padding-left: 10%;
  padding-right: 10%;
}
@media (max-width: 980px) {
  .default-container {
    padding-left: 25px;
    padding-right: 25px;
  }
}

.addIcon {
  display: inline-flex;
  border-radius: 100%;
  background: linear-gradient(rgba(255, 0, 68, 0.6), rgba(0, 0, 0, 0.4));
  padding: 5px;
  margin-left: 10px;
  color: #ffffff;
}

.app-error {
  background: rgba(255, 0, 68, 0.6);
  color: #ffffff;
  padding: 20px;
  border-radius: 5px;
}

section h2 {
  text-transform: capitalize;
  min-height: 100px;
  padding-top: 50px;
}

.loading-simple {
  display: flex;
  font-weight: bold;
  align-items: center;
  justify-content: center;
  color: #4ad;
}

p {
  margin-top: 15px;
  margin-bottom: 10px;
  line-height: 25px;
}

.message-sent {
  background: #ffffff;
  color: #7a01fa;
  padding: 25px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: bold;
  bottom: 0;
  width: 40%;
  min-width: 200px;
  max-width: 800px;
  margin: auto;
  margin-top: 25px;
  margin-bottom: 25px;
  border-radius: 20px;
  box-shadow: 0 3px 18px rgba(0, 0, 0, 0.0509803922);
}
.message-sent svg {
  fill: #7a01fa;
}
.message-sent a {
  color: #7a01fa;
}

.send-message-bt {
  background: #2b2b2c;
  color: #ffffff;
  min-width: 100px;
  height: 50px;
  text-align: center;
  cursor: pointer;
}

.message-sent-check {
  fill: #ffffff;
  width: 50px;
  height: 32px;
}

.about-section .about-section-wrap {
  margin: auto;
  padding-top: 50px;
  min-height: 600px;
}

input, select, textarea, button {
  border: 1px solid #ddd;
  background: #ffffff;
  min-height: 35px;
  padding: 5px;
}

.app-bt, .app-profile-page-container .modal-box button, .comment-wrap .app-bt, .comment-wrap .transparent-bt, .comment-wrap .load-more-blog-bt, .comment-wrap .homepage-bt,
.comment-wrap button, .homepage-bt, .load-more-blog-bt, .transparent-bt {
  background: transparent;
  min-width: 100px;
  min-height: 35px;
  text-align: center;
  border: 1px solid #00d6ff;
  padding-left: 25px;
  padding-right: 25px;
  color: #00d6ff;
  cursor: pointer;
  border-radius: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  max-width: 250px;
  margin: auto;
  text-align: center;
}
.app-bt:hover, .app-profile-page-container .modal-box button:hover,
.comment-wrap button:hover, .homepage-bt:hover, .load-more-blog-bt:hover, .transparent-bt:hover {
  background: transparent;
  font-weight: bold;
}
.app-bt svg, .app-profile-page-container .modal-box button svg, .comment-wrap .app-bt svg, .comment-wrap .transparent-bt svg, .comment-wrap .load-more-blog-bt svg, .comment-wrap .homepage-bt svg,
.comment-wrap button svg, .homepage-bt svg, .load-more-blog-bt svg, .transparent-bt svg {
  fill: #00d6ff;
}

.transparent-bt {
  border: none;
  color: rgba(0, 0, 0, 0.4);
}

.got-a-project-bt {
  background: #ffffff;
  min-width: 250px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #00d6ff;
  font-size: 16px;
  font-weight: bold;
}
.got-a-project-bt:hover {
  color: #00d6ff;
  font-weight: bold;
  background: #ffffff;
}

.add-note-bt svg {
  fill: #00d6ff;
}

.cute-upload-bt {
  padding: 10px;
  cursor: pointer;
  color: #00d6ff;
}
.cute-upload-bt svg {
  fill: #00d6ff;
}

.got-a-project-bt-top {
  min-width: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: transparent;
  border-radius: 5px;
  color: #000;
  border: 1px solid transparent;
  padding-left: 10px;
  padding-right: 10px;
  height: 50px;
  margin-left: 5px;
}
.got-a-project-bt-top:hover {
  font-weight: bold;
  text-decoration: underline;
}
.got-a-project-bt-top .addIcon {
  background: transparent;
}
@media (max-width: 980px) {
  .got-a-project-bt-top {
    display: none;
  }
}

.submit-search-bt {
  display: flex;
  align-items: center;
  background: transparent;
}
.submit-search-bt svg {
  width: 18px;
  height: 25px;
}

.load-more-blog-bt {
  margin-top: 20px;
}

.read-bt {
  margin-top: 25px;
  display: block;
}

.homepage-bt {
  margin: 0;
  background: #7a01fa;
  border-color: #7a01fa;
  color: #ffffff;
  border-radius: 8px;
  text-transform: uppercase;
  text-decoration: none;
}
.homepage-bt svg {
  margin-left: 10px;
  fill: #ffffff;
}
.homepage-bt:hover {
  background: #000;
  border-color: #000;
}

.rounded-bt {
  border-radius: 8px;
  border: 1px solid #443344;
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #443344;
  transition: all 0.3s;
}

@font-face {
  font-family: "PoppinsBold";
  src: local("Poppins-Bold"), url("../../../../fonts/Poppins/Poppins-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "PoppinsRegular";
  src: local("Poppins-Regular"), url("../../../../fonts/Poppins/Poppins-Regular.ttf") format("truetype");
}
.bread-crumb li {
  list-style-type: none;
  display: inline-flex;
  align-items: center;
}
.bread-crumb li a {
  padding: 5px;
  color: #ffffff;
}
.bread-crumb li a:hover {
  text-decoration: underline;
}
.bread-crumb li::after {
  content: ">";
  display: inline-block;
}
.bread-crumb li.main-article-list::after {
  content: "";
}
.bread-crumb li.main-article-list a {
  font-weight: bold;
  text-transform: uppercase;
}

.blog-core {
  justify-content: center;
}
.blog-core .blog-core-main {
  max-width: 800px;
  width: 100%;
  margin: auto;
}
.blog-core .blog-core-main article {
  margin-bottom: 10px;
  border-radius: 20px;
}
.blog-core img {
  max-width: 100%;
}

.blog-page .article-cover {
  max-width: 250px;
  width: 250px;
  min-width: 200px;
  background: transparent;
  padding: 20px;
}
@media (max-width: 1024px) {
  .blog-page .article-cover {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
  }
}
.blog-page .article-core {
  padding-top: 25px;
}
.blog-page .blog-title {
  text-align: center;
  display: flex;
  justify-content: space-between;
}
.blog-page .blog-article {
  border: 1px solid #2b2b2c;
  padding: 50px;
  border-radius: 5px;
  text-align: left;
}
.blog-page .blog-article {
  border-color: #f8f8f8;
  box-shadow: 0 3px 18px rgba(0, 0, 0, 0.0509803922);
}

.blog-page-core {
  display: flex;
  justify-content: center;
}
@media (max-width: 980px) {
  .blog-page-core {
    flex-direction: column;
  }
}
.blog-page-core .blog-main-core {
  width: 80%;
  max-width: 2000px;
  min-width: 250px;
}
@media (max-width: 1022px) {
  .blog-page-core .blog-main-core {
    width: 100%;
  }
}
.blog-page-core .blog-main-core .blog-top-title {
  display: flex;
  flex-direction: column;
  padding-top: 30px;
}
.blog-page-core .blog-main-core .blog-top-title h1 {
  text-transform: capitalize;
  font-size: 50px;
}
.blog-page-core .blog-main-core .blog-date-published {
  color: rgba(0, 0, 0, 0.4);
  padding-left: 10px;
}
.blog-page-core .blog-main-core .blog-date-published:hover {
  color: #ffffff;
}
.blog-page-core .message-sent {
  width: 100%;
  margin-bottom: 50px;
  box-shadow: 0 3px 18px rgba(0, 0, 0, 0.0509803922);
}

.comment-list {
  padding-bottom: 25px;
}
.comment-list h2 {
  text-transform: capitalize;
  color: #00d6ff;
}
.comment-list article {
  padding: 20px;
  padding-top: 10px;
  border-radius: 5px;
}
.comment-list .comment-title-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.comment-wrap {
  text-align: center;
  background: #ffffff;
  padding: 20px;
  border-radius: 20px;
  padding-bottom: 100px;
  padding-top: 25px;
  color: #7a01fa;
}
.comment-wrap label {
  display: block;
  text-align: left;
}
.comment-wrap input,
.comment-wrap textarea {
  display: block;
  width: 100%;
  border: none;
  border-bottom: 2px solid #7a01fa;
}
.comment-wrap .app-bt, .comment-wrap .app-profile-page-container .modal-box button, .app-profile-page-container .modal-box .comment-wrap button, .comment-wrap .transparent-bt, .comment-wrap .load-more-blog-bt, .comment-wrap .homepage-bt,
.comment-wrap button,
.comment-wrap button {
  border-color: #7a01fa;
  color: #7a01fa;
  background: transparent;
  margin: 0;
}
.comment-wrap h2 {
  margin-bottom: 15px;
}

.blog-main-content {
  min-height: 250px;
  margin-top: 10px;
  margin-bottom: 25px;
  border-radius: 5px;
  font-size: 125%;
}

.comment-button-wrap {
  display: flex;
  justify-content: space-between;
}
.comment-button-wrap h2 {
  text-align: left;
}
@media (max-width: 980px) {
  .comment-button-wrap button {
    max-height: 35px;
    justify-content: center;
  }
}

.about-section .about-section-wrap {
  padding-left: 10%;
  padding-right: 10%;
}
@media (max-width: 980px) {
  .about-section .about-section-wrap {
    padding-left: 25px;
    padding-right: 25px;
  }
}

.about-me {
  justify-content: space-between;
}

.app-login {
  width: 80%;
  margin: 0 auto;
}
@media (max-width: 900px) {
  .app-login {
    width: 95%;
  }
}
.app-login .login-title {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  color: #000;
  padding-bottom: 50px;
}
.app-login .login-title .app-bt, .app-login .login-title .app-profile-page-container .modal-box button, .app-profile-page-container .modal-box .app-login .login-title button, .app-login .login-title .transparent-bt, .app-login .login-title .load-more-blog-bt, .app-login .login-title .homepage-bt,
.app-login .login-title .comment-wrap button,
.comment-wrap .app-login .login-title button {
  margin: 0;
}
.app-login .error-login-wrap {
  background: #eee;
  margin-top: 20px 25px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 55px;
  color: rgba(0, 0, 0, 0.4);
  margin-bottom: 15px;
}
.app-login .login-logo-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 100%;
  gap: 25px;
}
.app-login .app-login-wrap {
  padding: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  flex-direction: column;
  margin: 0 auto;
  max-width: 400px;
  margin-bottom: 50px;
  box-shadow: 0 3px 18px rgba(0, 0, 0, 0.0509803922);
  transition: all 0.3s;
  border-radius: 10px;
}
@media (max-width: 1024px) {
  .app-login .app-login-wrap {
    margin-top: 10px;
  }
}
.app-login .app-login-wrap:hover {
  padding-top: 28px;
}
.app-login .app-login-wrap h2 {
  color: #7a01fa;
}
.app-login p.error {
  background: #eee;
  color: rgba(0, 0, 0, 0.4);
  text-align: center;
  padding: 5px;
  border-radius: 5px;
}
.app-login .login-form {
  width: 100%;
}
.app-login .login-footer {
  display: flex;
  gap: 25px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: rgba(0, 0, 0, 0.4);
}
.app-login .login-footer a {
  color: rgba(0, 0, 0, 0.4);
}
.app-login button {
  background: transparent;
  border: 1px solid #7a01fa;
  color: #7a01fa;
  border-radius: 20px;
}
.app-login button:hover {
  font-weight: bold;
  background: transparent;
}
.app-login select {
  display: block;
  height: 35px;
  width: 100%;
  border: none;
  border-bottom: 1px solid #e5e5e5;
  margin-top: 10px;
}
.app-login input {
  margin-bottom: 10px;
  display: block;
  width: 100%;
  height: 35px;
  border: none;
  border-bottom: 1px solid #e5e5e5;
}
.app-login .loginMessage a {
  color: rgba(0, 0, 0, 0.4);
}
.app-login .loginMessage a:hover {
  color: #000;
}
.app-login label {
  margin-top: 10px;
  color: #000;
}

.app-profile-page-container {
  max-width: 900px;
  width: 100%;
}
@media (max-width: 980px) {
  .app-profile-page-container {
    padding: 0;
  }
}
.app-profile-page-container .profile-main-content {
  padding: 25px;
}
.app-profile-page-container .modal-box h2 {
  text-transform: uppercase;
}
.app-profile-page-container .modal-box button {
  margin: 0;
}
.app-profile-page-container .app-profile-wrap {
  min-height: 600px;
}
.app-profile-page-container .user-main-profile {
  width: 120px;
  height: 120px;
  display: block;
  max-width: 120px;
  border-radius: 100%;
}
.app-profile-page-container .change-profile-dialog-footer {
  border-top: 1px solid #ddd;
  justify-content: flex-end;
  padding-top: 5px;
  display: flex;
  gap: 5px;
}
.app-profile-page-container .change-profile-dialog-footer .app-bt, .app-profile-page-container .change-profile-dialog-footer .transparent-bt, .app-profile-page-container .change-profile-dialog-footer .load-more-blog-bt, .app-profile-page-container .change-profile-dialog-footer .homepage-bt,
.app-profile-page-container .change-profile-dialog-footer .comment-wrap button,
.comment-wrap .app-profile-page-container .change-profile-dialog-footer button, .app-profile-page-container .change-profile-dialog-footer .modal-box button, .app-profile-page-container .modal-box .change-profile-dialog-footer button {
  margin: 0;
}
.app-profile-page-container .selected-image-confirm {
  display: flex;
  justify-content: center;
  padding-top: 25px;
  padding-bottom: 25px;
}
.app-profile-page-container .selected-image-confirm img {
  display: block;
  width: 150px;
  height: 150px;
  max-width: 150px;
  border-radius: 100%;
}
.app-profile-page-container .app-profile-cover {
  background: #7a01fa;
  min-height: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: rgba(255, 255, 255, 0.6);
  border-radius: 10px;
}
.app-profile-page-container .app-profile-cover h1 {
  color: #ffffff;
  text-align: center;
}
.app-profile-page-container .app-profile-pic-wrap {
  display: flex;
  align-items: center;
  justify-content: start;
  background: transparent;
}
.app-profile-page-container .files-list {
  display: flex;
  justify-content: space-between;
}
.app-profile-page-container .files-list img {
  margin: 5px;
  box-shadow: 0 3px 18px rgba(0, 0, 0, 0.0509803922);
}
.app-profile-page-container .files-list img:hover {
  border: 2px solid #ffffff;
}
.app-profile-page-container .files-list .file-list-right-col {
  background: #ffffff;
  border-left: 1px solid #ddd;
  padding: 10px;
  display: flex;
  flex-direction: column;
}
.app-profile-page-container .app-profile-photo {
  position: relative;
  padding: 10px;
  margin-top: -56px;
  z-index: 1;
}
.app-profile-page-container .app-profile-photo img {
  border: 5px solid #ffffff;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.0509803922);
}
.app-profile-page-container .app-profile-photo .change-profile-pic-bt {
  position: absolute;
  top: 28px;
  right: 5px;
  background-color: #000;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #00d6ff;
  border-radius: 100%;
  cursor: pointer;
}
.app-profile-page-container .app-profile-photo .change-profile-pic-bt svg {
  fill: #ffffff;
}
.app-profile-page-container .app-profile-photo .no-profile-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border-radius: 100%;
  width: 100px;
  height: 100px;
  padding: 10px;
  border: 5px solid #ffffff;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.0509803922);
}
.app-profile-page-container .app-profile-photo .no-profile-wrap svg {
  width: 50px;
  height: 50px;
  fill: #7a01fa;
}
.app-profile-page-container .view-profile-wrap .app-profile-photo-info h2 {
  margin-top: 45px;
  margin-bottom: 10px;
}
.app-profile-page-container .view-profile-wrap .app-profile-photo-info p {
  color: rgba(0, 0, 0, 0.4);
}
.app-profile-page-container .welcome-profile {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.app-profile-page-container .update-profile-core {
  padding: 10px;
}
.app-profile-page-container .app-profile-photo-info {
  margin-top: -50px;
}
.app-profile-page-container .app-profile-photo-info h2 {
  padding-top: 25px;
  color: #7a01fa;
  font-size: 1.4em;
}
.app-profile-page-container .app-profile-photo-info .app-bt, .app-profile-page-container .app-profile-photo-info .transparent-bt, .app-profile-page-container .app-profile-photo-info .load-more-blog-bt, .app-profile-page-container .app-profile-photo-info .homepage-bt,
.app-profile-page-container .app-profile-photo-info .comment-wrap button,
.comment-wrap .app-profile-page-container .app-profile-photo-info button, .app-profile-page-container .app-profile-photo-info .modal-box button, .app-profile-page-container .modal-box .app-profile-photo-info button {
  margin-left: 5px;
}
.app-profile-page-container .profile-menu {
  width: 100%;
}
.app-profile-page-container .profile-menu a {
  color: rgba(0, 0, 0, 0.4);
}
.app-profile-page-container .profile-menu ul {
  width: 100%;
}
#profile-top-menu {
  position: fixed;
  top: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
  box-shadow: 0 -3px 18px rgba(0, 0, 0, 0.0509803922);
  overflow-y: scroll;
  padding-top: 0;
}
#profile-top-menu .modal-box {
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 0;
  height: 100%;
  margin-top: 0;
  max-width: 400px;
  min-height: 400px;
}
@media (max-width: 980px) {
  #profile-top-menu .modal-box {
    max-width: 80%;
  }
}
#profile-top-menu ul {
  display: flex;
  flex-direction: column;
}
#profile-top-menu li {
  list-style-type: none;
  margin-top: 10px;
}
#profile-top-menu .close-app-bt {
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
}
#profile-top-menu .close-app-bt svg {
  width: 25px;
  height: 25px;
}
#profile-top-menu .user-photo {
  justify-content: center;
  text-align: center;
}
#profile-top-menu svg {
  fill: #00d6ff;
}

.top-right-menu {
  position: relative;
}
.top-right-menu svg {
  fill: #7a01fa;
}
.top-right-menu .user-name-bt {
  cursor: pointer;
  padding-left: 10px;
  padding-right: 10px;
  color: #7a01fa;
}

.note-editor {
  border: 1px solid #ddd;
  min-height: 50px;
  padding: 5px;
  margin-bottom: 25px;
}

.app-profile-wrap {
  padding-bottom: 50px;
}
.app-profile-wrap form label {
  display: block;
}
.app-profile-wrap form input,
.app-profile-wrap form select,
.app-profile-wrap form textarea {
  display: block;
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  border: 1px solid #ddd;
  margin-bottom: 10px;
  box-shadow: 0 3px 18px rgba(0, 0, 0, 0.0509803922);
}
.app-profile-wrap .user-notes h2 {
  padding-top: 20px;
  padding-bottom: 20px;
}
@media (max-width: 1024px) {
  .app-profile-wrap .user-notes {
    padding: 10px;
  }
}
.app-profile-wrap .user-notes article {
  padding: 20px;
  border-radius: 10px;
  position: relative;
  box-shadow: 0 3px 18px rgba(0, 0, 0, 0.0509803922);
  margin-bottom: 15px;
}
.app-profile-wrap .user-notes article h3[contentEditable=true] {
  display: block;
  border: 1px solid #ddd;
  width: 100%;
  padding: 5px;
  font-weight: normal;
  margin-bottom: 5px;
  box-shadow: 0 3px 18px rgba(0, 0, 0, 0.0509803922);
  border-radius: 5px;
}
.app-profile-wrap .user-notes article div[contentEditable=true] {
  display: block;
  border: 1px solid #ddd;
  width: 100%;
  padding: 5px;
  font-weight: normal;
  height: 200px;
  max-height: 200px;
  min-height: 200px;
  overflow-y: scroll;
  box-shadow: 0 3px 18px rgba(0, 0, 0, 0.0509803922);
  border-radius: 5px;
}
.app-profile-wrap .user-notes .update-note-footer {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
  gap: 5px;
  padding-top: 10px;
}
.app-profile-wrap .user-notes .update-note-footer button {
  margin: 0;
}
.app-profile-wrap .user-notes .allow-update-note {
  position: absolute;
  top: -5px;
  right: 0;
  background: #00d6ff;
  color: #ffffff;
  padding: 2px;
  border-radius: 5px;
}
.app-profile-wrap .user-notes .error-update-note {
  position: absolute;
  top: -5px;
  right: 0;
  background: #000;
  color: #ffffff;
  padding: 2px;
  border-radius: 5px;
}
.app-profile-wrap .user-notes .user-notes-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.app-profile-wrap .user-notes .user-notes-title .transparent-bt {
  margin: 0;
  color: #00d6ff;
  position: absolute;
  right: 0;
}

.app-profile-core {
  display: flex;
  justify-content: center;
}

.app-user-stats {
  display: flex;
  justify-content: center;
  gap: 25px;
  padding: 10px;
}
@media (max-width: 1024px) {
  .app-user-stats {
    flex-direction: column;
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

.uploadLevelContainer {
  height: 5px;
  border-radius: 2px;
  background: #eee;
  position: relative;
  display: block;
}
.uploadLevelContainer .uploadLevel {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background: #00d6ff;
}

@keyframes showTopRightMenu {
  0% {
    width: 10%;
  }
  100% {
    width: 30%;
  }
}
.add-note-footer {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.profile-update-box {
  max-width: 250px;
  padding: 25px;
  font-size: 90%;
  background: #ffffff;
  color: rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  box-shadow: 0 3px 18px rgba(0, 0, 0, 0.0509803922);
}
.profile-update-box h2 {
  color: #7a01fa;
}
.profile-update-box svg {
  fill: #7a01fa;
}

.update-flex {
  max-width: 250px;
  flex-direction: column;
  gap: 25px;
}

.search-page {
  padding-top: 5px;
}
@media (max-width: 900px) {
  .search-page {
    padding-top: 25px;
  }
}

.submit-search-bt {
  cursor: pointer;
}

.search-menu {
  border-bottom: 2px solid #ddd;
  background: #ffffff;
  padding-top: 15px;
  border-radius: 5px;
  box-shadow: 0 3px 18px rgba(0, 0, 0, 0.0509803922);
}

.search-menu ul {
  display: inline-flex;
  justify-content: start;
}
.search-menu ul li {
  list-style-type: none;
}
.search-menu ul li a {
  display: block;
  padding-left: 10px;
  padding-right: 10px;
  min-height: 35px;
  color: #ffffff;
}
.search-menu ul li a:hover {
  border-bottom: 2px solid #7a01fa;
  color: #7a01fa;
  font-weight: bold;
}

.search-result-wrap article h3 a {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}
.search-result-wrap article h3 svg {
  background: #eee;
  border-radius: 100%;
}

.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 17;
  display: flex;
  justify-content: center;
  overflow-y: scroll;
}
.modal-container .modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 10;
}
.modal-container .modal-box {
  position: relative;
  background: #ffffff;
  width: 50%;
  min-width: 250px;
  max-width: 800px;
  margin: auto;
  margin-top: 50px;
  padding: 25px;
  border: 1px solid #00d6ff;
  z-index: 11;
  border-radius: 5px;
  color: rgba(0, 0, 0, 0.4);
  border-radius: 8px;
}
.modal-container .modal-box a {
  color: #7a01fa;
}
@media (max-width: 1024px) {
  .modal-container .modal-box {
    width: 90%;
  }
}
.modal-container .modal-footer {
  display: flex;
  align-items: flex-end;
  padding: 10px 25px;
  border-top: 1px solid #e5e5e5;
  margin-top: 25px;
}

article {
  background: #ffffff;
  flex-direction: column;
  align-items: center;
  border-radius: 5px;
  color: #7a01fa;
}
article:hover {
  box-shadow: 0 3px 18px rgba(221, 221, 221, 0.8666666667);
}
article a {
  color: #7a01fa;
}
article h2, article h3 {
  color: #7a01fa;
}
article .article-core {
  flex-direction: column;
}
@media (max-width: 900px) {
  article {
    flex-direction: column;
  }
}

article {
  margin-right: 10px;
  background: #fff;
}
@media (max-width: 980px) {
  article {
    margin-bottom: 20px;
    padding: 20px;
    margin-right: 0;
  }
}

@media (max-width: 1022px) {
  article.app-flex, article.article-blog-page {
    flex-direction: column;
  }
}
@media (max-width: 1022px) {
  article.app-flex .article-cover, article.article-blog-page .article-cover {
    width: 100%;
    min-width: 100%;
  }
}
@media (max-width: 1022px) {
  article.app-flex .article-core, article.article-blog-page .article-core {
    width: 100%;
    min-width: 100%;
  }
}

.article-cover {
  min-width: 100%;
  max-width: 100%;
  min-height: 150px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  background-color: #eee;
}
.article-cover img {
  display: block;
  width: 100%;
  min-width: 100%;
}
@media (max-width: 1024px) {
  .article-cover {
    width: 100%;
    max-width: 100%;
    min-height: 120px;
  }
}

.article-core {
  padding: 25px;
  flex-direction: column;
  padding-top: 0;
  justify-content: start;
}
.article-core h3 {
  text-transform: uppercase;
}

.each-slide-effect > div {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background-size: cover;
  height: 350px;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 50px;
}
.each-slide-effect > div h2 {
  text-transform: uppercase;
}
.each-slide-effect > div .app-bt, .each-slide-effect > div .transparent-bt, .each-slide-effect > div .load-more-blog-bt, .each-slide-effect > div .homepage-bt,
.each-slide-effect > div .comment-wrap button,
.comment-wrap .each-slide-effect > div button, .each-slide-effect > div .app-profile-page-container .modal-box button, .app-profile-page-container .modal-box .each-slide-effect > div button {
  border-radius: 8px;
  border-color: #7a01fa;
  background: #7a01fa;
  color: #ffffff;
  text-transform: uppercase;
  font-weight: 600;
}

.right-arrow-icon, .left-arrow-icon {
  font-size: 45px;
  color: #ffffff;
  width: 44px;
  height: 44px;
  border-radius: 100%;
  border: 2px solid transparent;
  transition: all 0.3s;
  background: transparent;
}

.banner-slide-wrap .left-arrow-icon {
  display: none !important;
}
.banner-slide-wrap .right-arrow-icon {
  font-size: 50px;
  right: 25px !important;
}

.left-arrow-icon {
  left: 15px !important;
}
.left-arrow-icon:hover {
  transform: scale(1.01) translateY(-5px);
}
.left-arrow-icon svg {
  fill: #ffffff;
}

.right-arrow-icon {
  right: 15px !important;
}
.right-arrow-icon:hover {
  transform: scale(1.01) translateY(-5px);
}
.right-arrow-icon svg {
  fill: #ffffff;
}

@keyframes animateSliderIcon {
  0% {
    margin-top: -5px;
  }
  100% {
    margin-top: 0;
  }
}
.each-slide-effect .slide-wrap {
  position: relative;
  min-height: 450px;
}
.each-slide-effect .slide-wrap .slide-overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  height: 100%;
  min-height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.4);
}
.each-slide-effect .slide-wrap .slide-box {
  z-index: 3;
  padding-left: 50px;
  padding-right: 50px;
  max-width: 450px;
  min-width: 250px;
  margin: 0 auto;
  color: #ffffff;
}
.each-slide-effect .slide-wrap .slide-box .homepage-bt {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 35px;
  transition: all 0.3s;
  background: #ffd700;
  min-height: 50px;
  border-color: #ffd700;
  color: #000;
}
.each-slide-effect .slide-wrap .slide-box .homepage-bt:hover {
  font-weight: bold;
}

.who-am-i {
  background: #ffffff;
  width: 70%;
  padding: 50px;
  min-height: 400px;
  color: #000;
  margin-left: 25px;
  z-index: 10;
  min-width: 200px;
  max-width: 2000px;
  margin-top: 25px;
}
@media (max-width: 900px) {
  .who-am-i {
    margin: 0 auto;
    width: 100%;
    padding: 25px;
  }
}
.who-am-i p {
  line-height: 25px;
}
.who-am-i h2 {
  min-height: 50px;
  padding-bottom: 5px;
  color: #7a01fa;
}
.who-am-i .app-bt, .who-am-i .transparent-bt, .who-am-i .load-more-blog-bt, .who-am-i .homepage-bt,
.who-am-i .comment-wrap button,
.comment-wrap .who-am-i button, .who-am-i .app-profile-page-container .modal-box button, .app-profile-page-container .modal-box .who-am-i button {
  margin: 0;
  background: #7a01fa;
  border-color: #7a01fa;
  color: #ffffff;
  border-radius: 8px;
  text-transform: uppercase;
}
.who-am-i .homepage-bt {
  max-width: 150px;
  text-align: center;
  justify-content: center;
}
.who-am-i .homepage-bt:hover {
  background: #000;
}

.services-container .react-slideshow-container .nav {
  top: 100%;
}
.services-container .react-slideshow-container .right-arrow-icon {
  left: -50px;
}
@media (max-width: 500px) {
  .services-container .react-slideshow-container .right-arrow-icon {
    left: 50px;
  }
}
.services-container .react-slideshow-container .left-arrow-icon {
  left: -100px !important;
}
@media (max-width: 500px) {
  .services-container .react-slideshow-container .left-arrow-icon {
    left: 0px !important;
  }
}

.sticky-nav-wrap {
  position: fixed;
  top: 65px;
  right: 0;
  z-index: 14;
  background: #7a01fa;
  width: 50px;
  min-height: 50px;
  padding: 2px;
  width: 32px;
  border-radius: 8px;
}
.sticky-nav-wrap svg {
  fill: #ffffff;
  width: 25px;
  height: 25px;
}
.sticky-nav-wrap li {
  list-style-type: none;
}
.sticky-nav-wrap ul {
  padding: 0;
}

.enhenced-blog-wrap {
  padding: 10px;
}
.enhenced-blog-wrap h2 {
  text-align: center;
}
.enhenced-blog-wrap .homepage-bt {
  margin: 0 auto;
}
.enhenced-blog-wrap .gasycms-pagination-articles-wrap {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 10px;
  padding: 25px;
}
@media (max-width: 900px) {
  .enhenced-blog-wrap .gasycms-pagination-articles-wrap {
    display: flex;
    flex-direction: column;
  }
}

.projects-container {
  padding: 25px;
}
.projects-container .pagination-articles-wrap {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 25px;
}
@media (max-width: 1024px) {
  .projects-container .pagination-articles-wrap {
    display: flex;
    flex-direction: column;
    gap: 25px;
    justify-content: center;
  }
}
.projects-container .homepage-bt {
  margin: 0 auto;
  margin-top: 15px;
}

.enable-widget-button {
  cursor: pointer;
  background: #fff;
  border-radius: 100%;
  width: 25px;
  height: 25px;
  box-shadow: rgba(0, 0, 0, 0.0509803922);
  position: absolute;
  left: 0;
  top: -1px;
}

.enable-widget-button-right {
  animation: 2s enabled;
  cursor: pointer;
  background: #fff;
  border-radius: 100%;
  width: 25px;
  height: 25px;
  box-shadow: rgba(0, 0, 0, 0.0509803922);
  position: absolute;
  left: 62%;
  top: -1px;
}

.enable-widget-container {
  padding: 2px;
  width: 70px;
  position: relative;
  height: 25px;
  border-radius: 30px;
}

@keyframes enabled {
  0% {
    left: 0%;
  }
  100% {
    left: 62%;
  }
}
.elem-slide-in {
  margin-left: 0%;
  animation: showSlideIn 1s;
  transition: all 0.3s;
}
.elem-slide-in .elem-wrap-hide {
  opacity: 1;
  display: flex;
}

.elem-wrap-hide {
  opacity: 0;
}

@keyframes showSlideIn {
  0% {
    margin-left: -30%;
  }
  100% {
    margin-left: 0;
  }
}
.elem-section {
  min-height: 200px;
}

.elem-row {
  display: flex;
  justify-content: center;
  max-width: 1400px;
  margin: 0 auto;
  width: 80%;
  padding: 50px;
  gap: 25px;
}
@media (max-width: 900px) {
  .elem-row {
    flex-direction: column;
    padding: 10px;
    width: 95%;
  }
}

.elem-text-violet {
  color: #7a01fa;
}

.elem-section-title {
  text-align: center;
  font-size: 45px;
}

.elem-col-animate {
  background: #ffffff;
  border: 1px solid #ddd;
  transition: all 0.3s;
  border-radius: 10px;
  padding: 25px;
  min-height: 200px;
  min-width: 250px;
  width: 30%;
}
@media (max-width: 1024px) {
  .elem-col-animate {
    max-width: 100%;
  }
}
.elem-col-animate:hover {
  box-shadow: 0 3px 18px rgba(221, 221, 221, 0.8666666667);
}

.elem-zoom-out {
  transition: all 0.3s;
}
.elem-zoom-out:hover {
  transform: scale(1.01);
}

.elem-logo-icon {
  max-width: 150px;
  height: auto;
}

.elem-zoom-in {
  transition: all 0.3s;
}
.elem-zoom-in:hover {
  transform: scale(0.95);
}

.elem-text-gold {
  color: #ffd700;
}

.elem-pos-rel {
  position: relative;
}

.elem-pos-abs {
  position: absolute;
}

.elem-pos-fix {
  position: fixed;
}

.elem-pos-rel {
  position: relative;
}

.elem-pos-abs-top-35px {
  top: 35px;
}

.elem-pos-abs-top-50px {
  top: 50px;
}

.elem-max-sec {
  max-width: 1400px;
  min-height: 400px;
  margin: 0 auto;
}

.elem-font-bold {
  font-weight: bold;
}

.elem-font-ita {
  font-style: italic;
}

.elem-font-upper {
  text-transform: uppercase;
}

.elem-font-lower {
  text-transform: lowercase;
}

/* texts*/
.elem-text-center {
  text-align: center;
}

/*sizes*/
.elem-width-50px {
  width: 50px;
}

.elem-width-100px {
  width: 100px;
}

.elem-width-150px {
  width: 150px;
}

.elem-width-200px {
  width: 200px;
}

.elem-width-250px {
  width: 250px;
}

.elem-width-full {
  width: 100%;
}

.elem-height-50px {
  height: 50px;
}

.elem-height-100px {
  height: 150px;
}

.elem-height-150px {
  height: 150px;
}

.elem-height-200px {
  height: 200px;
}

.elem-height-250px {
  height: 250px;
}

.elem-flex {
  display: flex;
}

.elem-flex-inline {
  display: inline-flex;
}

.elem-flex-center-inline {
  display: flex;
  justify-content: center;
}

.elem-flex-end-inline {
  display: flex;
  justify-content: flex-end;
}

.elem-flex-start-inline {
  display: flex;
  justify-content: flex-start;
}

.elem-flex-center-col {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.elem-flex-space-between {
  display: flex;
  justify-content: space-between;
}

.elem-gap-10px {
  gap: 10px;
}

.elem-gap-25p {
  gap: 25%;
}

.elem-gap-50p {
  gap: 50%;
}

.elem-grid {
  display: grid;
}

.elem-margin-5px {
  margin: 5px;
}

.elem-margin-10px {
  margin: 10px;
}

.elem-margin-15px {
  margin: 15px;
}

.elem-margin-20px {
  margin: 20px;
}

.elem-margin-25px {
  margin: 25px;
}

.elem-margin-top-10px {
  margin-top: 10px;
}

.elem-margin-bottom-10px {
  margin-bottom: 10px;
}

.elem-margin-left-10px {
  margin-left: 10px;
}

.elem-margin-right-10px {
  margin-right: 10px;
}

.elem-padding-5px {
  padding: 5px;
}

.elem-padding-10px {
  padding: 10px;
}

.elem-padding-15px {
  padding: 15px;
}

.elem-padding-20px {
  padding: 20px;
}

.elem-padding-25px {
  padding: 25px;
}

.elem-bg-light {
  background: #ffffff;
}

.elem-bg-dark {
  background: #000;
}

.elem-border-rad-bt-light {
  border-radius: 5px;
}

.elem-border-radius-bt {
  border-radius: 8px;
}

.elem-border-radius-card {
  border-radius: 20px;
}

.elem-border-light {
  border: 1px solid #e5e5e5;
}

.elem-hide {
  display: none;
}

.elem-show {
  display: block;
}

.elem-show-flex {
  display: flex;
}

.elem-show-grid {
  display: grid;
}

.new-classroom-form {
  color: rgba(0, 0, 0, 0.4);
  background: #ffffff;
  padding: 25px;
  margin-top: 25px;
  border-radius: 8px;
}
.new-classroom-form input {
  border: 1px solid #ddd;
  display: block;
  min-width: 100%;
  min-height: 35px;
}
.new-classroom-form select {
  border: 1px solid #ddd;
  display: block;
  min-width: 100%;
  min-height: 35px;
}
.profile-home-dashboard {
  max-width: 250px;
}
.profile-home-dashboard .dashboard-item {
  background: #ffffff;
  margin-top: 25px;
  margin-bottom: 25px;
  padding: 25px;
  color: rgba(0, 0, 0, 0.4);
  border-radius: 5px;
}
.profile-home-dashboard h2 {
  color: #7a01fa;
  font-size: 16px;
}

.question-icon {
  border-radius: 100%;
  background: #7a01fa;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  padding: 5px;
  cursor: pointer;
  max-width: 32px;
  min-height: 32px;
}

.classroom-cover {
  margin-top: 50px;
}

.classrooms-list {
  background: #ffffff;
  background: 25px;
  width: 70%;
  padding: 20px;
}

.left-col-form {
  min-width: 250px;
  width: 20%;
  background: #ffffff;
  padding: 20px;
  padding-top: 40px;
}

.classrooms-item-wrap {
  display: grid;
  grid-template-columns: 30% 30% 30%;
}

.classroom-item {
  padding: 10px;
  color: rgba(0, 0, 0, 0.4);
  border: 1px solid #ddd;
  box-shadow: 0 3px 18px rgba(0, 0, 0, 0.0509803922);
  margin: 5px;
  border-radius: 10px;
}
.classroom-item .classroom-item-title {
  font-size: 1.2em;
  color: rgba(0, 0, 0, 0.4);
  transition: all 0.3s;
}
.classroom-item .classroom-item-title:hover {
  color: #7a01fa;
}
.classroom-item .classroom-item-footer {
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid #ddd;
}
.classroom-item .classroom-item-footer .rounded-bt {
  border-color: #7a01fa;
  color: #7a01fa;
  max-width: 90px;
}

.animate-icon {
  transition: all 0.3s;
}
.animate-icon:hover {
  transform: scale(1.01) translateY(-5px);
}

