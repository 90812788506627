.each-slide-effect > div{
	display: flex;
	align-items: flex-start;
	justify-content: center;
	background-size: cover;
	height: 350px;
	flex-direction: column;
	background-color: rgba(0,0,0,0.6);
	padding: 50px;

	h2{
		text-transform: uppercase;
	}
	.app-bt{
		
	    border-radius: 8px;
		border-color: $background3;
		background: $background3;
		color: $light;
		text-transform: uppercase;
		font-weight: 600;
	}
}
%slider-icon{
	font-size: 45px; 
	color: $light; 
	width: 44px;
	height: 44px;
	border-radius:100%;
	border: 2px solid transparent;
	transition: all 0.3s;
	background:transparent;

}
.banner-slide-wrap{

	.left-arrow-icon{
	    display: none!important;
    }
    .right-arrow-icon{

        font-size: 50px; 
	right: 25px!important;

    }
 }
.left-arrow-icon{
	    @extend %slider-icon;
	    left: 15px!important;
		&:hover{
			// animation: animateSliderIcon 1s;
			transform: scale(1.01) translateY(-5px);
		}
		svg{
			fill: $light;
		}
	}
	.right-arrow-icon{
		@extend %slider-icon;
		right: 15px!important;
		&:hover{
			transform: scale(1.01) translateY(-5px);
			// animation: animateSliderIcon 1s;
		}
		svg{
			fill: $light;
		}
	}
@keyframes animateSliderIcon{
	0%{
		margin-top: -5px;
	}
	100%{
		margin-top: 0;
	}
	
}
.each-slide-effect .slide-wrap{
	position: relative;
	min-height: 450px;
	.slide-overlay{
		position: absolute;
		top: 0;
		left: 0;
		z-index: 2;
		height: 100%;
		min-height: 100%;
		width: 100%;
		background: rgba(0,0,0,0.4);
	}
	.slide-box{
		
		z-index: 3;
		padding-left: 50px; 
		padding-right: 50px; 
		max-width: 450px; 
		min-width: 250px;
                margin: 0 auto;
		color: $light;
		.homepage-bt{
			display: flex; 
			justify-content: center; 
			flex-direction: column; 
			align-items: center; 
			margin-top: 35px;
			transition: all 0.3s;
		        background: #ffd700;
		        min-height: 50px;
		        border-color: #ffd700;
		        color: $dark;	
			&:hover{
				font-weight: bold;
			}
		}
	}
	
}


