.classroom-cover{
	margin-top: 50px;
}

.classrooms-list{
	background: $light;
	background: 25px;
	width: 70%;
	padding: 20px;
}
.left-col-form{
	min-width: 250px; 
	width: 20%; 
	background: $light;
	padding: 20px; 
	padding-top: 40px;
}
.classrooms-item-wrap{
	display: grid; 
    grid-template-columns:30% 30% 30%;
}
.classroom-item{
	padding:10px;
	color: $grayMenu;
	border: 1px solid $border-color;
	box-shadow: 0 3px 18px #0000000d;
	margin: 5px;
	border-radius: 10px;

	.classroom-item-title{
		font-size: 1.2em;
		color: $grayMenu; 
		transition: all 0.3s;
		&:hover{
			color: $background3;
		}
	}
	.classroom-item-footer{
		display: flex; 
		justify-content: flex-end;
		border-top: 1px solid $border-color;
		.rounded-bt{
			border-color: $background3;
			color: $background3;
			max-width: 90px;
		}
	}
}