article {
		background: $light;
		flex-direction: column;
		align-items: center;
//		box-shadow: 0 3px 18px #0000000d;
	//	border: 2px solid #f8f8f8;
		border-radius: 5px;
		color: $background3;
&:hover{
box-shadow: 0 3px 18px #dddddddd;
}
		a{
			color: $background3;
		}
		h2, h3{
			color: $background3;
		}
		.article-core {
			flex-direction: column;
		}

		@media (max-width: 900px) {
			flex-direction: column;
		}
}

article{
	margin-right: 10px;
	background: #fff;
	@media (max-width: $large)
	{
		margin-bottom: 20px;
		padding: 20px;
		margin-right: 0;
	}
}
article.app-flex, article.article-blog-page{
	@media(max-width: $large2)
	{
		flex-direction: column;
	}
	.article-cover{
		@media(max-width: $large2)
		{
			width: 100%;
			min-width: 100%;
		}
		
	}
	.article-core{
		@media(max-width: $large2)
		{
			width: 100%;
			min-width: 100%;
		}
		
	}
}


.article-cover {
	//width: 200px;
	min-width: 100%;
	max-width: 100%;
	min-height: 150px;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: top center;
	//max-height: 100px;
	background-color: #eee;
	//margin-bottom: 10px;

	img {

		display: block;
		width: 100%;
		min-width: 100%;
	}

	@media (max-width: $tablet) {
		width: 100%;
		max-width: 100%;
		min-height: 120px;
	}
}

.article-core {
	padding: 25px;
	flex-direction: column;
	padding-top: 0;
	justify-content: start;

	h3 {
		text-transform: uppercase;
	}
}
