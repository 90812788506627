//elem slide
.elem-slide-in {
	margin-left: 0%;
	animation: showSlideIn 1s;
	transition: all 0.3s;

	.elem-wrap-hide {
		opacity: 1;
		display: flex;
	}
}

.elem-wrap-hide {

	//display: none;
	opacity: 0;
}


@keyframes showSlideIn {

	0% {
		margin-left: -30%;
	}

	100% {
		margin-left: 0;
	}
}

//elem sections and elem rows
.elem-section {
	min-height: 200px;

}

.elem-row {
	display: flex;
	justify-content: center;
	max-width: 1400px;
	margin: 0 auto;
	width: 80%;
	padding: 50px;
	gap: 25px;

	@media (max-width: $mobile) {
		flex-direction: column;
		padding: 10px;
		width: 95%;
	}
}

.elem-col {}

.elem-text-violet {
	color: $background3;
}

.elem-section-title {
	text-align: center;
	font-size: 45px;
}

.elem-col-animate {
	background: $light;
	border: 1px solid #ddd;
	transition: all 0.3s;
	border-radius: 10px;
	padding: 25px;
	min-height: 200px;
	// box-shadow: 0 3px 18px #dddddddd;
	min-width: 250px;
	width: 30%;
	// max-width: 250px;

	@media (max-width: $tablet) {
		max-width: 100%;
	}

	&:hover {
		// border: 2px solid $background3;

		box-shadow: 0 3px 18px #dddddddd;
	}
}

.elem-zoom-out {
	transition: all 0.3s;

	&:hover {
		transform: scale(1.01);
	}
}

.elem-logo-icon {
	max-width: 150px;
	height: auto;
}

.elem-zoom-in {
	transition: all 0.3s;

	&:hover {
		transform: scale(0.95);
	}
}

.elem-text-gold {
	color: $golden-color;
}

//positions
.elem-pos-rel {
	position: relative;
}

.elem-pos-abs {
	position: absolute;
}

.elem-pos-fix {
	position: fixed;
}

.elem-pos-rel {
	position: relative;
}

//absolute 
.elem-pos-abs-top-35px {
	top: 35px;
}

.elem-pos-abs-top-50px {
	top: 50px;
}

//sections 
.elem-max-sec {
	max-width: 1400px;
	min-height: 400px;
	margin: 0 auto;
}

//fonts
.elem-font-bold {
	font-weight: bold;
}

.elem-font-ita {
	font-style: italic;
}

.elem-font-upper {
	text-transform: uppercase;
}

.elem-font-lower {
	text-transform: lowercase;
}

/* texts*/
.elem-text-center {

	text-align: center;
}

/*sizes*/
//width
.elem-width-50px {
	width: 50px;
}

.elem-width-100px {
	width: 100px;
}

.elem-width-150px {
	width: 150px;
}

.elem-width-200px {
	width: 200px;
}

.elem-width-250px {
	width: 250px;
}

.elem-width-full {
	width: 100%;
}

//height
.elem-height-50px {
	height: 50px;
}

.elem-height-100px {
	height: 150px;
}

.elem-height-150px {
	height: 150px;
}

.elem-height-200px {
	height: 200px;
}

.elem-height-250px {
	height: 250px;
}

//flex 
.elem-flex {
	display: flex;
}

.elem-flex-inline {
	display: inline-flex;
}

.elem-flex-center-inline {
	display: flex;
	justify-content: center;
}

.elem-flex-end-inline {
	display: flex;
	justify-content: flex-end;
}

.elem-flex-start-inline {
	display: flex;
	justify-content: flex-start
}

.elem-flex-center-col {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.elem-flex-space-between {
	display: flex;
	justify-content: space-between;
}

//flex gap 
.elem-gap-10px {
	gap: 10px;
}

.elem-gap-25p {
	gap: 25%;
}

.elem-gap-50p {
	gap: 50%;
}

//grid 
.elem-grid {
	display: grid;
}

//margin 
.elem-margin-5px {
	margin: 5px;
}

.elem-margin-10px {
	margin: 10px;
}

.elem-margin-15px {
	margin: 15px;
}

.elem-margin-20px {
	margin: 20px;
}

.elem-margin-25px {
	margin: 25px;
}

.elem-margin-top-10px {
	margin-top: 10px;
}

.elem-margin-bottom-10px {
	margin-bottom: 10px;
}

.elem-margin-left-10px {
	margin-left: 10px;
}

.elem-margin-right-10px {
	margin-right: 10px;
}

//padding
.elem-padding-5px {
	padding: 5px;
}

.elem-padding-10px {
	padding: 10px;
}

.elem-padding-15px {
	padding: 15px;
}

.elem-padding-20px {
	padding: 20px;
}

.elem-padding-25px {
	padding: 25px;
}

//backgrounds 
.elem-bg-light {
	background: $light;
}

.elem-bg-dark {
	background: $dark;
}

//border radius 
.elem-border-rad-bt-light {
	border-radius: 5px;
}

.elem-border-radius-bt {
	border-radius: 8px;
}

.elem-border-radius-card {
	border-radius: 20px;
}

//borders 
.elem-border-light {
	border: 1px solid #e5e5e5;
}

//hide 
.elem-hide {
	display: none;
}

//show 
.elem-show {
	display: block;
}

.elem-show-flex {
	display: flex;
}

.elem-show-grid {
	display: grid;
}